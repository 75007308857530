import React, { useState } from "react";
import FacilityTree from "./treeStuct";
import { useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core";
import { OrgView } from "./orgView";
import Grid from "@material-ui/core/Grid";
import CreateFacilityParent from "../createFacility";
import { actions } from "pfb-binder";

const styles = (theme) => ({
  root: {
    backgroundColor: "#F8F8F8",
    height: "100%",
    overflowx: "hidden",
  },
  paper: {
    // backgroundColor: "#fff",
    // marginLeft: "20px",
    padding: 10,
    height: "100%",
    overflowY: "auto",
  },
});

const ManageFacilityParent = (props) => {
  const dispatch = useDispatch();
  const ClientId = props?.clientId ?? "e68df9ff-a8c7-40e9-91c9-a834891d429d";
  const tenantid = props?.tenantid ?? "e68df9ff-a8c7-40e9-91c9-a834891d429d";
  const [state, setState] = useState({
    openTree: true,
    form: false,
    key: null,
    treeView: 0,
    entity: null,
    disableAddNew: false,
    createfs: false,
  });

  const [data, setData] = useState([]);
  const [EditKey, setEditKey] = useState("");

  React.useEffect(() => {
    getdata();
    //eslint-disable-next-line
    if (props.disableAddNew) {
      setState({
        disableAddNew: props.disableAddNew,
      });
    }
  }, [props.disableAddNew]);

  // console.log("props.disableAddNew",props.disableAddNew)

  const getdata = async () => {
    // debugger;
    try {
      let value = await dispatch(
        actions.ORGANIZATION_TREE_READ({
          client_id: ClientId,
        })
      );
      let v = value?.payload?.data;

      setData(v);
    } catch (error) {
      console.log(error);
    }
  };

  const getAlldata = async (clientid) => {
    try {
      let value = await dispatch(
        actions.ORGANIZATION_TREE_READ({
          client_id: clientid,
        })
      );
      let v = value?.payload?.data;

      setData(v);
    } catch (error) {
      console.log(error);
    }
  };
  const Createopen = () => {
    setState({
      createfs: true,
    });
  };
  const CreateClose = () => {
    setEditKey("");
    setState({
      createfs: false,
    });
  };

  const hospitalsTree = () => {
    setState({
      openTree: !state.openTree,
    });
  };

  const formClick = (getkey, fromType, isEdit) => {
    setState({
      ...state,
      form: true,
      key: getkey,
      isexternal: fromType,
      disableAddNew: isEdit ? isEdit : false,
    });
  };
  const setKeyfuntion = (getkey, isEdit) => {
    let entity = getkey.split("/")[0];
    setState({
      key: getkey,
      entity: entity,
      form: false,
      disableAddNew: false,
    });
  };

  const completeForm = (isEdit) => {
    setState({
      ...state,
      form: false,
      disableAddNew: isEdit ? isEdit : false,
    });
  };

  const handleEdit = (key) => {
    setEditKey(key);
    setState({
      createfs: true,
    });
  };

  const { classes } = props;
  return (
    <div id={"organization-parent-div"} className={classes.root}>
      <Grid
        id={"organization-parent-grid"}
        container
        style={{
          height: "100%",
        }}
      >
        <Grid
          id={"organization-parent-sub-grid"}
          item
          xs={3}
          style={{ zIndex: 1, height: "100%" }}
        >
          <FacilityTree
            parent_id={"treestuck"}
            onClickTreebtn={() => hospitalsTree}
            onClickformClick={formClick}
            treeis={state.openTree}
            isform={state.form}
            getKeyfuntion={setKeyfuntion}
            isexternal={false}
            disableAddNew={state.disableAddNew}
            Createopen={Createopen}
            client_id={ClientId}
            data={data}
            tenantid={tenantid}
            view={props.disableAddNew}
          />
        </Grid>

        <Grid id={"organization-grid"} item xs={9} style={{ height: "100%" }}>
          <div id={"organization-div"} className={classes.paper}>
            {state?.createfs === true ? (
              <CreateFacilityParent
                client_id={ClientId}
                EditKey={EditKey}
                CreateClose={CreateClose}
                getAlldata={getAlldata}
                tenantid={tenantid}
              />
            ) : (
              <OrgView
                isform={state.form}
                handlekey={state.key}
                onClickformClick={formClick}
                entity={state.entity}
                completeForm={completeForm}
                ClientId={ClientId}
                handleEdit={handleEdit}
                tenantid={tenantid}
                disableAddNew={props.disableAddNew}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

ManageFacilityParent.defaultProps = {};

export default withStyles(styles)(ManageFacilityParent);
