import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core";
import { CreateFacility } from "./createFacility";
import { actions } from "pfb-binder";
import { useDispatch } from "react-redux";

const styles = (theme) => ({
  root: {
    flex: 1,
    height: "100%",
  },
});
const CreateFacilityParent = (props) => {
  const dispatch = useDispatch();

  const { client_id, Createopen, CreateClose, EditKey, getAlldata, tenantid } =
    props;

  useEffect(() => {
    getContactPrefix();
    getContactSuffix();
    getPositionDesi();
    contactDetailMode();
    contactDetailPriority();
    contactDetailUse();
    idTypeIdenifi();
    orgUseIdenifi();
    aliastype();
    organizationEntityType();
    orgLevelCare();
    orgParEntity();
    getContactdetailsmode();
    //getMappedForms();
  }, []);

  const getContactPrefix = () => {
    const res = dispatch(actions.ORGANIZATION_GET_CONTACT_PREFIX({}));
  };

  const getContactSuffix = () => {
    const res = dispatch(actions.ORGANIZATION_GET_CONTACT_SUFFIX({}));
  };

  const getPositionDesi = () => {
    const res = dispatch(actions.ORGANIZATION_GET_CONTACT_DESI({}));
  };

  const contactDetailPriority = () => {
    const res = dispatch(actions.ORGANIZATION_GET_CONTACTDETAILPRIORITY({}));
  };

  const contactDetailUse = () => {
    const res = dispatch(actions.ORGANIZATION_GET_CONTACTDETAILUSE({}));
  };

  const contactDetailMode = () => {
    const res = dispatch(actions.ORGANIZATION_GET_CONTACTDETAILMODE({}));
  };

  const idTypeIdenifi = () => {
    const res = dispatch(actions.ORGANIZATION_GET_IDTYPE_IDENTIF({}));
  };

  const orgUseIdenifi = () => {
    const res = dispatch(actions.ORGANIZATION_GET_USE_IDENTIFICATION({}));
  };

  const aliastype = () => {
    const res = dispatch(actions.ORGANIZATION_GET_ALIAS_TYPE({}));
  };

  const organizationEntityType = () => {
    const res = dispatch(actions.ORGANIZATION_ENTITY_TYPE({}));
  };

  const orgParEntity = () => {
    const res = dispatch(actions.ORGANIZATION_GET_PARENT_ENTITY({}));
  };

  const orgLevelCare = () => {
    const res = dispatch(actions.ORGANIZATION_GET_LEVEL_CARE({}));
  };

  const getContactdetailsmode = () => {
    const res = dispatch(actions.ORGANIZATION_GET_LEVEL_CARE({}));
  };

  //const getMappedForms = (disease, country) => { this.props.GET_MAPPED_FORMS({ diseaseId: '', countryId: '' }); };

  const { classes } = props;

  return (
    <div className={classes.root}>
      <CreateFacility
        data={EditKey}
        client_id={client_id}
        CreateClose={CreateClose}
        getAlldata={getAlldata}
        tenantid={tenantid}
      />
    </div>
  );
};

export default withStyles(styles)(CreateFacilityParent);
