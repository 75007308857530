/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc Exporting all the assets from /src/assets
 */

export { default as InfoIcon } from "./infoIcon";
export { default as CheckIcon } from "./checkIcon";
export { default as HomeDeliveryIcon } from "./homeDeliveryIcon";
export { default as AddIcon } from "./addIcon";
export { default as TrashIcon } from "./trashIcon";
export { default as SupplementDoseIcon } from "./supplementDoseIcon";
export { default as SupplementTotalDoseIcon } from "./supplementTotalDoseIcon";
export { default as PillIcon } from "./pillIcon";
export { default as HoldingHandIcon } from "./holdingHandIcon";
export { default as HoldingHandWithPillIcon } from "./holdingHandWithPillIcon";
export { default as EstimateIcon } from "./estimateIcon";
export { default as TimesheetIcon } from "./timesheetIcon";
export { default as ScheduleIcon } from "./scheduleIcon";
export { default as GroceryShelfIcon } from "./groceryShelfIcon";
export { default as StackIcon } from "./stackIcon";
export { default as StackOverflowIcon } from "./stackOverflowIcon";
export { default as NoAccessIcon } from "./noAccessIcon";
export { default as DollarIcon } from "./dollarIcon";
export { default as ReplyArrowIcon } from "./replyArrowIcon";
export { default as DocumentIcon } from "./documentIcon";
export { default as DownwardIcon } from "./downwardIcon";
export { default as FrequencyIcon } from "./frequencyIcon";
export { default as PharmacyIcon } from "./pharmacyIcon";
export { default as DocumentBlack } from "./documentBlack";
export { default as PillDark } from "./pillDark";
export { default as TaskIcon } from "./taskIcon";
export { default as Pills } from "./pills";
export { default as Clock } from "./clock";
export { default as Calender } from "./calender";
export { default as Diagnosis } from "./diagnosis";
export { default as Speciality } from "./speciality";
export { default as LocationIcon } from "./location";
export { default as DrugIcon } from "./drugIcon";
export { default as CreateIcon } from "./createIcon";
export { default as DollarOutlineIcon } from "./dollarOutllined";
export { default as SchoolIcon } from "./schoolIcon";
export { default as Close } from "./close";
export { default as Tick } from "./tick";
export { default as TaskAndDocumentIcon } from "./taskAndDocumentIcon";
export { default as MasterIcon } from "./masterIcon";
export { default as PrinterIcon } from "./printerIcon";
export { default as WarningIcon } from "./warningIcon";
export { default as StethoscopeIcon } from "./stethoscope";
export { default as DrugPrint } from "./drugPrint";
export { default as CalendarIcon } from "./calenderIcon";
export { default as GridIcon } from "./gridIcon";
export { default as ListIcon } from "./listIcon";
export { default as CartFilledIcon } from "./cartFilledIcon";
export { default as HandWithPillIcon } from "./handWithPillIcon";
export { default as ReturnPolicyIcon } from "./returnPolicyIcon";
export { default as SunIcon } from "./sun";
export { default as MoonIcon } from "./moon";
export { default as TickIcon } from "./tickIcon";
export { default as BuiildingIcon } from "./buildingIcons";
export { default as AdminIcon } from "./adminIcon";
export { default as UserIcon } from "./userIcons";
export { default as PractitionerIcon } from "./practitionerIcon";
export { default as ThemesIcon } from "./themesIcon";
export { default as RuleBuilderIcon } from "./ruleBuilder";
export { default as IssueMedication } from "./issueMedication";
export { default as SeniorCitizenIcon } from "./seniorCitizen";
export { default as PediatricIcon } from "./pediatricIcon";
export { default as DoctorIcon } from "./doctorIcon";
export { default as SlidingScaleIcon } from "./slidingScaleIcon";
export { default as PrnIcon } from "./prnIcon";
export { default as CartIcon } from "./cartIcon";
export * from './archived';
export * from './filterIcon';
export * from './filterIconForDashBoard';
export * from './notification';
export * from './forInvestigation';
export * from './investigated';
export * from './verified';
export * from './archived';
export * from './dragDrop';
export * from './drag';
export * from './locationIcon';
export * from './transfer';
export * from './reject';
export * from './Enterprise';
export * from './Facility';
export * from './Organization';
export * from './Person';
export * from './Address';
export * from './Fax';
export * from './Level';
export * from './Levelofcare';
export * from './Phone';
export * from './Contactperson';
export * from './FacilityCode';
