import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { actions } from "pfb-binder";
import { withNavBars } from "../../HOCs";
import { AddEditClient } from "./addEditClient";

const AddEditClientParent = (props) => {
  //   const { location = {} } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const [isClientEdit, setClientEdit] = React.useState(false);

  const editData = useSelector(
    (state) => state?.manageClientSlice?.getSingleClient
  );
  React.useEffect(() => {
    if (location?.state?.isEdit) {
      getSingleClient(location?.state?._id);
      setClientEdit(location?.state?.isEdit);
      // api for edit by key or id
    }
    //eslint-disable-next-line
  }, []);

  const getSingleClient = async (id) => {
    await dispatch(actions.GET_SINGLE_CLIENT({ id: id }));
  };

  return (
    <>
      <AddEditClient
        isEdit={isClientEdit}
        editData={isClientEdit ? editData?.data[0] : null}
        setClientEdit={setClientEdit}
      />
    </>
  );
};

export default withNavBars(AddEditClientParent);
