import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Authentication } from "atp-authentication";
import "react-toastify/dist/ReactToastify.css";
import { withTheme } from "@material-ui/core";
import { AxiosCall } from "../../utils";

const Login = (props) => {
  const [redirect, setRedirect] = useState("/Home");

  let RoleId = localStorage.getItem("RoleId");

  useEffect(async () => {
    let params = {
      db_name: process.env.REACT_APP_DB,
      entity: "IDM_PermissionRoleMapping",
      filter: `IDM_PermissionRoleMapping.roleid=='${RoleId}' && IDM_PermissionRoleMapping.activestatus==true`,
      return_fields: "IDM_PermissionRoleMapping",
    };
    let res = await AxiosCall(
      "post",
      `${process.env.REACT_APP_BASE_URL}/api/read_documents`,
      params
    );

    let RoleName = res?.result[0]?.rolename;

    // debugger
    if (RoleName?.toString()?.toLowerCase()?.includes("notifi")) {
      setRedirect("/notify");
    } else if (RoleName?.toString()?.toLowerCase()?.includes("verfiy")) {
      setRedirect("/verify");
    } else {
      setRedirect("/Home");
    }
  }, [RoleId]);

  return (
    <div>
      <Authentication
        showSignUp={false}
        showReset={false}
        theme={props?.theme}
        logo={"images/icons/logo.png"}
      />
    </div>
  );
};

export default withRouter(withTheme(Login));
