import {
  Grid,
  TextField,
  makeStyles,
  Typography,
  Divider,
  Button,
} from "@material-ui/core";
import React from "react";
import { Select } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "pfb-binder";
import { Message } from "../../utils";
import { DrawerContext } from "../../contexts";
import { DrawerProps } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  girdItem: {
    width: "100%",
  },
  header: {
    padding: "10px 20px",
    color: theme.palette.primary.main,
  },
  formNameParent: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  countryParent: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  btnParent: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  marginR: {
    marginRight: theme.spacing(2),
  },
}));
export const AddAddressInput = ({
  updateAddress = () => false,
  Address = [],
  index = 0,
  isEdit = false,
  onClose = () => false,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const drawerContext = React.useContext(DrawerContext);

  const addressAPiCalls = useSelector((state) => state?.organizationSlice);

  React.useEffect(() => {
    const getData = async () => {
      await dispatch(actions.ORGANIZATION_GET_ADD_USE());
      await dispatch(actions.ORGANIZATION_GET_ADD_TYPE());
    };
    getData();
    // eslint-disable-next-line
  }, []);

  const [state, setState] = React.useState({
    use: isEdit ? Address[index]?.use : {},
    type: isEdit ? Address[index]?.type : {},
    address1: isEdit ? Address[index]?.address1 : "",
    address2: isEdit ? Address[index]?.address2 : "",
    address3: isEdit ? Address[index]?.address3 : "",
    Location: isEdit ? Address[index]?.Location : {},
    city: isEdit ? Address[index]?.city : "",
    district: isEdit ? Address[index]?.district : "",
    state: isEdit ? Address[index]?.state : "",
    country: isEdit ? Address[index]?.country : "",
    pincode: isEdit ? Address[index]?.pincode : "",
    errorValidation: {
      error: {
        address1: false,
        use: false,
        type: false,
        address2: false,
        address3: false,
        city: false,
        district: false,
        state: false,
        country: false,
        pincode: false,
        Location: false,
      },
    },
  });

  const updateState = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const validation = () => {
    let isValidaddress1 = state?.address1?.trim().length === 0 ? false : true;
    // let isValiduse =  (Object.keys(state?.use)?.length === 0 && (state?.use !== null || undefined )) ? false : true;
    // let isValidtype = (Object.keys(state?.type)?.length === 0 && (state?.type !== null || undefined )) ? false : true;
    let isValidaddress2 = state?.address2?.trim().length === 0 ? false : true;
    let isValidaddress3 = state?.address3?.trim().length === 0 ? false : true;
    let isValidcity = state?.city?.trim().length === 0 ? false : true;
    let isValiddistrict = state?.district?.trim().length === 0 ? false : true;
    let isValidstate = state?.state?.trim().length === 0 ? false : true;
    let isValidcountry = state?.country?.trim().length === 0 ? false : true;
    let isValidpincode = state?.pincode?.trim().length === 0 ? false : true;
    // let isValidlocation = state?.Location?.length === 0 ? false : true;

    if (
      isValidaddress1 &&
      isValidaddress2 &&
      isValidaddress3 &&
      isValidcity &&
      isValiddistrict &&
      isValidstate &&
      isValidcountry &&
      isValidpincode
    ) {
      return true;
    } else {
      state.errorValidation.error = {
        address1: state?.address1?.trim().length === 0 ? true : false,
        // use: (Object.keys(state?.use)?.length === 0 && (state?.use !== null || undefined )) ? false : true,
        // type: (Object.keys(state?.type)?.length === 0  && (state?.type !== null || undefined )) ? false : true,
        address2: state?.address2?.trim().length === 0 ? true : false,
        address3: state?.address3?.trim().length === 0 ? true : false,
        city: state?.city?.trim().length === 0 ? true : false,
        district: state?.district?.trim().length === 0 ? true : false,
        state: state?.state?.trim().length === 0 ? true : false,
        country: state?.country?.trim().length === 0 ? true : false,
        pincode: state?.pincode?.trim().length === 0 ? true : false,
        // Location: state?.Location?.length === 0 ? true : false,
      };
      setState({ ...state });
    }
  };

  const addEditAddress = () => {
    const isValidated = validation();

    if (!isValidated) {
      return false;
    }

    const modifyAddressArray = JSON.parse(JSON.stringify(Address));
    if (isEdit) {
      modifyAddressArray[index] = state;
    } else {
      modifyAddressArray.push(state);
    }
    updateAddress("Address", modifyAddressArray);
    onClose();

    setState({
      use: {},
      type: {},
      address1: "",
      address2: "",
      address3: "",
      Location: {},
      city: "",
      district: "",
      state: "",
      country: "",
      pincode: "",
      errorValidation: {
        error: {
          address1: false,
          use: false,
          type: false,
          address2: false,
          address3: false,
          city: false,
          district: false,
          state: false,
          country: false,
          pincode: false,
          Location: false,
        },
      },
    });
  };

  const onDrawerClosed = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      direction: DrawerProps.direction.right,
      open: false,
    });

    setState({
      use: {},
      type: {},
      address1: "",
      address2: "",
      address3: "",
      Location: {},
      city: "",
      district: "",
      state: "",
      country: "",
      pincode: "",
      errorValidation: {
        error: {
          address1: false,
          use: false,
          type: false,
          address2: false,
          address3: false,
          city: false,
          district: false,
          state: false,
          country: false,
          pincode: false,
          Location: false,
        },
      },
    });
  };

  return (
    <div className={classes.root}>
      <Grid>
        <Grid item>
          <Typography className={classes.header}>
            {isEdit ? "Update Address" : "Add Address"}
          </Typography>
          <Divider />
        </Grid>
        <form noValidate autoComplete="off">
          <Grid item className={classes.countryParent}>
            {/* <div style={{ }}> */}
            <div>
              <Select
                name="use"
                error={state.errorValidation.error.use}
                helperText={
                  state.errorValidation.error.use === true
                    ? Message.requiredMessage
                    : ""
                }
                label={"Use"}
                id="use"
                value={[state.use]}
                handleselect={(data) => updateState("use", data)}
                option={addressAPiCalls?.orgAddUSE?.data ?? []}
              />
            </div>
            <div>
              <Select
                name="Type"
                error={state.errorValidation.error.type}
                helperText={
                  state.errorValidation.error.type === true
                    ? Message.requiredMessage
                    : ""
                }
                label={"Type"}
                id="addtype"
                handleselect={(data) => updateState("type", data)}
                value={[state.type]}
                option={addressAPiCalls?.orgAddType?.data ?? []}
              />
            </div>

            {/* </div> */}
          </Grid>

          {/* <Grid item className={classes.formNameParent}>

                        <Location
                            value={state?.Location}
                            label={"Location"}
                            handleChange={(value) => updateState("Location", value)}
                            id={"location"}
                            isReadonly={false}
                            isrequired={false}
                            errorValidation={state.errorValidation.error.Location ?? {}}
                            disabled={false}
                            helperText={
                                state.errorValidation.error.Location === true ? Message.requiredMessage : ''
                            }
                        />

                    </Grid> */}

          <Grid item className={classes.formNameParent}>
            <Typography variant="body1">Address Line 1</Typography>
            <TextField
              name="Address"
              fullWidth
              placeholder="Add Address"
              id="addressline1"
              onChange={(value) => updateState("address1", value.target.value)}
              value={state?.address1 || ""}
              variant="outlined"
              size="small"
              margin="dense"
              error={state.errorValidation.error.address1}
              helperText={
                state.errorValidation.error.address1 === true
                  ? Message.requiredMessage
                  : ""
              }
            />
          </Grid>
          <Grid item className={classes.formNameParent}>
            <Typography variant="body1">Address Line 2</Typography>
            <TextField
              name="addressline2"
              fullWidth
              placeholder="Add Address"
              id="addressline2"
              onChange={(value) => updateState("address2", value.target.value)}
              value={state?.address2 || ""}
              variant="outlined"
              size="small"
              margin="dense"
              error={state.errorValidation.error.address2}
              helperText={
                state.errorValidation.error.address2 === true
                  ? Message.requiredMessage
                  : ""
              }
            />
          </Grid>
          <Grid item className={classes.formNameParent}>
            <Typography variant="body1">Address Line 3</Typography>
            <TextField
              name="name"
              fullWidth
              placeholder="Add Address"
              id="addressline3"
              onChange={(value) => updateState("address3", value.target.value)}
              value={state?.address3 || ""}
              variant="outlined"
              size="small"
              margin="dense"
              error={state.errorValidation.error.address3}
              helperText={
                state.errorValidation.error.address3 === true
                  ? Message.requiredMessage
                  : ""
              }
            />
          </Grid>
          <Grid item className={classes.formNameParent}>
            <Typography variant="body1">City</Typography>
            <TextField
              name="city"
              fullWidth
              placeholder="Add City"
              id="city"
              onChange={(e) => {
                const re = /^[a-zA-Z\s]+$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  updateState("city", e.target.value);
                }
              }}
              value={state?.city || ""}
              variant="outlined"
              size="small"
              margin="dense"
              error={state.errorValidation.error.city}
              helperText={
                state.errorValidation.error.city === true
                  ? Message.requiredMessage
                  : ""
              }
            />
          </Grid>
          <Grid item className={classes.formNameParent}>
            <Typography variant="body1">District</Typography>
            <TextField
              name="district"
              fullWidth
              placeholder="Add District"
              id="district"
              onChange={(e) => {
                const re = /^[a-zA-Z\s]+$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  updateState("district", e.target.value);
                }
              }}
              value={state?.district || ""}
              variant="outlined"
              size="small"
              margin="dense"
              error={state.errorValidation.error.district}
              helperText={
                state.errorValidation.error.district === true
                  ? Message.requiredMessage
                  : ""
              }
            />
          </Grid>
          <Grid item className={classes.formNameParent}>
            <Typography variant="body1">state</Typography>
            <TextField
              name="state"
              fullWidth
              placeholder="Add state"
              id="state"
              onChange={(e) => {
                const re = /^[a-zA-Z\s]+$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  updateState("state", e.target.value);
                }
              }}
              value={state?.state || ""}
              variant="outlined"
              size="small"
              margin="dense"
              error={state.errorValidation.error.state}
              helperText={
                state.errorValidation.error.state === true
                  ? Message.requiredMessage
                  : ""
              }
            />
          </Grid>
          <Grid item className={classes.formNameParent}>
            <Typography variant="body1">Country</Typography>
            <TextField
              name="country"
              fullWidth
              placeholder="Add Country"
              id="country"
              onChange={(e) => {
                const re = /^[a-zA-Z\s]+$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  updateState("country", e.target.value);
                }
              }}
              value={state?.country || ""}
              variant="outlined"
              size="small"
              margin="dense"
              error={state.errorValidation.error.country}
              helperText={
                state.errorValidation.error.country === true
                  ? Message.requiredMessage
                  : ""
              }
            />
          </Grid>
          <Grid item className={classes.formNameParent}>
            <Typography variant="body1">Pincode</Typography>
            <TextField
              name="pincode"
              fullWidth
              placeholder="Add Pincode"
              id="pincode"
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  updateState("pincode", e.target.value);
                }
              }}
              value={state?.pincode || ""}
              variant="outlined"
              size="small"
              margin="dense"
              error={state.errorValidation.error.pincode}
              helperText={
                state.errorValidation.error.pincode === true
                  ? Message.requiredMessage
                  : ""
              }
            />
          </Grid>

          <Grid item className={classes.btnParent}>
            <Button
              size="large"
              variant="outlined"
              color="primary"
              className={classes.marginR}
              onClick={onDrawerClosed}
              id="addAndUpdateAddressButton"
            >
              {"Cancel"}
            </Button>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={addEditAddress}
              id="addAndUpdateAddressButton"
            >
              {isEdit ? "Update" : "Add"}
            </Button>
          </Grid>
        </form>
      </Grid>
    </div>
  );
};
