import React from "react";
import * as WebDataRocksReact from "react-webdatarocks";
import "webdatarocks/webdatarocks.css";
import { withNavBars } from "../../HOCs";
import jwt_decode from "jwt-decode";
import ReportTemplate from "../../components/reporttemplate";
import Dailogdatefilter from "../../components/reportdailogfilter";
import { AlertContext } from "../../contexts";
import { AlertProps } from "../../utils";
import {
  CardActionArea,
  Card,
  Typography,
  Grid,
  IconButton,
  Box,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import { TbReportAnalytics } from "react-icons/tb";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import moment from "moment";

const ReportVerifier = () => {

  const reportList = [
    
    { id: "1da8333c-6dc7-4408-8168-571886092bcb", reportname: "STEMI Data - Cardiac Center in Arar" },
    { id: "62c41a83-4a15-4bf3-8c9e-0f57dc894415", reportname: "STEMI Data - King Fahad Medical City" },
    { id: "0caf9c34-70e6-4b25-a302-eb7b554a909f", reportname: "STEMI Data - Tameer Hospital" },
    { id: "08b91646-2957-4b34-8b83-b6aef433aa95", reportname: "STEMI Data - Rafha General Hospital" },
  ];
  const theme = useTheme();
  const pivot = React.useRef(null);
  const alert = React.useContext(AlertContext);
  const [open, setOpen] = React.useState(true);
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [showTo, setShowTo] = React.useState(new Date());
  const [showFilter, setShowFilter] = React.useState(false);
  const [showFrom, setShowFrom] = React.useState(new Date());
  const [formid, setformId] = React.useState();
  const [showReport, setShowReport] = React.useState(false);

  let singleData = [];
  const singleLevel = (data) => {


    for (let i = 0; i < data.length; i++) {
      let Result = {}
      let answer = data[i].formdetails[0].status.answers
      let answerLength = data[i]?.formdetails[0]?.status?.answers?.length

      Result["Patient Id"] = data[i].patientDetails[0]?.length !== 0
      ? data[i].patientDetails[0].Id
      : ""


      Result["gender"] = data[i].patientDetails[0]?.length !== 0 &&
        data[i].patientDetails[0]?.hasOwnProperty("gender")
        ? data[i].patientDetails[0]?.gender
        : "-"

      Result["mobile No"] = data[i].patientDetails[0]?.length !== 0 &&
        data[i]?.patientDetails[0]?.mobileno?.hasOwnProperty("number") &&
        data[i]?.patientDetails[0]?.mobileno?.hasOwnProperty("phoneCode")
        ? ` ${data[i]?.patientDetails[0]?.mobileno?.phoneCode}${data[i]?.patientDetails[0]?.mobileno?.number}`
        : "-"
      Result["Notifier Status"] = data[i].formdetails[0]?.length !== 0
        ? data[i].formdetails[0].status.Notifier_Status
        : ""

      Result["Verifier Status"] = data[i].formdetails[0]?.length !== 0
        ? data[i].formdetails[0].status.Verifier_Status
        : ""

      let obj = {}


      for (let j = 0; j < answer.length; j++) {
        if (answer[j]?.questionname == "Referred From Hospital") {
          obj[answer[j]?.questionname] = answer[j]?.answer?.answer?.value
        }
        else if( answer[j]?.questionname == "Thrombolytic Medication Administered")
        {
          obj[answer[j]?.questionname] = answer[j]?.answer?.answer?.value
        }
        else if( answer[j]?.questionname == "FIBRINOLYTCS")
        {
          obj[answer[j]?.questionname] = answer[j]?.answer?.answer?.value
        }
        else if(answer[j]?.questionname == "Date of admission(dd/mm/yy)")
        {
          let value =  answer[j]?.answer?.answer
          let value1 = moment(value).format('DD/MM/YYYY');
          obj[answer[j]?.questionname] = value1 
        }
        else {
          obj[answer[j]?.questionname] = answer[j]?.answer?.answer
        }

        Result["Date of admission(dd/mm/yy)"] = obj["Date of admission(dd/mm/yy)"]
        Result["Referred From Hospital"] = obj["Referred From Hospital"]
        Result["Mode of arrival"] = obj["Mode of arrival"]
        Result["Triage Time  (Door In)  (hh:mm)"] = obj["Triage Time  (Door In)  (hh:mm)"]
        Result["Time of first ECG  (hh:mm)"] = obj["Time of first ECG  (hh:mm)"]
        Result["Thrombolytic Medication Administered"] = obj["Thrombolytic Medication Administered"]
        Result["Time of thrombolytic administration (hh:mm)"] = obj["Time of thrombolytic administration (hh:mm)"]
        Result["Time of  1ry PCI began (hh:mm)"] = obj["Time of  1ry PCI began (hh:mm)"]
        Result["FIBRINOLYTCS"] = obj["FIBRINOLYTCS"]
      }
      obj = {}
      singleData.push(Result);
    }

    return singleData;
  };
  const normalize = (datas) => {
    let data = [];
    datas.forEach((e, i) => {
      if (datas[i].formdetails.length <= 1) {
        data.push(datas[i]);
      } else {
        datas[i].formdetails.forEach((e, j) => {
          let patientDetail = [...datas[i].patientDetails];
          data.push({
            formdetails: [datas[i].formdetails[j]],
            patientDetails: patientDetail,
          });
        });
      }
    });
    return data;
  };
  function customizeToolbar(toolbar) {
    let tabs = toolbar.getTabs();
    toolbar.getTabs = function () {
      delete tabs[0];
      delete tabs[1];
      delete tabs[2];
      delete tabs[4];
      delete tabs[5];
      return tabs;
    };
  }

  const handleSubmit = async () => {
    if (validation()) {

    let date1 = moment(fromDate).format("MM-DD-YYYY")
    let date2 = moment(toDate).format("MM-DD-YYYY")
    let date = new Date(`"${date1} 12:00:00 AM"`)
    let date4 = new Date(`"${date2} 11:59:59 PM"`)
    let fromdatevalue = moment(date).unix();
    let todatevalue = moment(date4).unix();

      const personId = jwt_decode(localStorage.getItem("queue_token"));
      const myHeader = new Headers();
      myHeader.append("content-type", "application/json");
      const data = await fetch(
        process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES,
        {
          method: "post",
          headers: myHeader,
          body: JSON.stringify({
            db_name: process.env.REACT_APP_DBNAME,
            filter: {
              verifier_id: personId?.personid,
              fromdate:fromdatevalue,
              enddate:todatevalue,
              formid:formid
            },
            queryid: process.env.REACT_APP_VERIFIER_QUERY,
          }),
        }
      );
      const result = await data.json();
      var report = {
        dataSource: {
          data: singleLevel(normalize(result.collection ? result.collection : result)),
        },
        options: {
          grid: {
            type: "flat",
            showGrandTotals: "off",
            showTotals: "off",
          },
        },
      };
      if (report.dataSource.data.length === 0) {
        pivot.current.webdatarocks.clear();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.warning,
          msg: "No Record",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else if (pivot.current?.webdatarocks !== null) {
        pivot.current.webdatarocks.setReport(report);
      }
      handleClose();
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Required Fields Are Empty",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const validation = () => {
    let valid = false;
    if (fromDate !== null && toDate !== null) return (valid = true);
    return valid;
  };
  const handleCloseReport = () => {
    
    setShowReport(false);
    setformId("")
    // setFromDate("")
    // setToDate("")
  } 

  return (
    <>
      <div
        style={{
          height: !showReport ? "100%" : "",
          backgroundColor: "#ECF0F7",
        }}
      >
        {!showReport && (
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px 16px",
              background: theme?.palette?.background?.table,
              height: "54px",
              borderBottom: " 1px solid #e0e0e0",
            }}
          >
            <Typography variant="body1">{"Reports"}</Typography>
          </Box>
        )}
        <Grid container>
          {!showReport &&
            reportList.map((e) => (
              <Grid item sm={6} xs={12} md={4} lg={4} xl={4}>
                <Card style={{ maxWidth: "98%", margin: "10px" }}>
                  <CardActionArea
                    style={{ padding: "15px" }}
                    onClick={() => {
                      setShowReport(true);
                      setformId(e?.id);
                      setOpen(true)
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>{e.reportname}</Typography>
                      <TbReportAnalytics size={24} />
                    </div>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
        </Grid>

        {showReport && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "5px",
                backgroundColor: "white",
              }}
            >
              <div>
                <IconButton size="small" onClick={() => handleCloseReport()}>
                  <ArrowBackIosRoundedIcon />
                </IconButton>
              </div>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {/* <Typography>{reportDetails.reportname}</Typography> */}
              </div>
            </div>
            <ReportTemplate
              showFilter={showFilter}
              showFrom={showFrom}
              showTo={showTo}
              setOpen={setOpen}
            />
            <WebDataRocksReact.Pivot
              ref={pivot}
              toolbar={true}
              width="100%"
              beforetoolbarcreated={customizeToolbar}
            />
            <Dailogdatefilter
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              handlechangefordate={() => false}
              open={open}
              setOpen={setOpen}
              setToDate={setToDate}
              setFromDate={setFromDate}
              setShowTo={setShowTo}
              setShowFrom={setShowFrom}
              state={{ open, toDate, fromDate }}
            />
          </>
        )}
      </div>
    </>
    // </ReportTemplate>
  );
};

export default withNavBars(ReportVerifier);
