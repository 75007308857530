import { Box, Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import { MuiSelect } from "../../../../components/muiSelect/MuiSelect";
import { actions } from "pfb-binder";
import { useDispatch, useSelector } from "react-redux";
// import { AlertContext } from "../../../../contexts";
// import { AlertProps } from "../../../../utils";

export const CreateClientAdmin = (props) => {
  const { clientId, state, setState, setClientEdit } = props;
  const dispatch = useDispatch();
  //   const alertContext = React.useContext(AlertContext);

  //   const [isEdit, setEdit] = React.useState(false);

  const prefixesList = useSelector((state) => ({
    p: state?.codingMaster?.getPrefix?.data,
    g: state?.codingMaster?.getGenderType?.data,
    r: state?.codingMaster?.getClientRole?.data,
  }));

  const updateState = (key, value) => {
    setState({ ...state, [key]: value });
  };
  React.useEffect(() => {
    if (clientId) {
      getAllDropDownList(clientId);
    }
    //eslint-disable-next-line
  }, [clientId]);

  const getAllDropDownList = async (clientId) => {
    let res = await dispatch(
      actions.GET_PESRON_BY_TENATID({ state: state?.clientid })
    );
    await dispatch(actions.GET_PREFIX({ state: "NAMEPREFIX" }));
    await dispatch(actions.GET_GENDER_TYPE({ state: "GENDER" }));
    if (res.payload.data.length === 0) {
      // debugger;
      let res = await dispatch(
        actions.GET_CLIENT_ROLE({
          state: state?.clientid,
          tenantId: state?.tenantid,
        })
      );
      if (res?.payload?.data) {
        let roleData = await dispatch(
          actions.GET_ROLE_MAPPING({ state: res?.payload?.data?.repoid })
        );
        setState({ ...state, role: roleData?.payload?.data });
      }
    } else {
      let data = res?.payload?.data[0];
      // console.log("data", data);
      setState({
        ...state,
        prefix: data?.prefix,
        firstName: data?.firstName,
        middleName: data?.middleName,
        lastName: data?.lastName,
        gender: data?.gender,
        email: data?.email,
        tenantid: data?.tenantid,
        clientid: data?.clientid,
        _key: data?._key,
        _id: data?._key,
        editEmail: data?.email,
      });
      setClientEdit(true);
    }
  };

  return (
    <Box style={{ padding: "8px", boxSizing: "border-box" }}>
      <Grid
        container
        direction="row"
        display="flex"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12} sm={6} lg={3}>
          <Typography variant="subtitle2" gutterBottom color="textSecondary">
            {"Prefix"}
            <span style={{ color: "red", marginLeft: "2px" }}>*</span>
          </Typography>
          <MuiSelect
            id="selectClientType"
            className="basic-single"
            classNamePrefix="select"
            value={prefixesList?.p?.find((l) => l.coding._id === state.prefix)}
            placeholder="Select Type"
            getOptionLabel={(option) => option?.coding?.display ?? ""}
            handleselect={(data) =>
              updateState("prefix", data?.coding?._id ?? "")
            }
            // label={"coding"}
            option={prefixesList?.p ?? []}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <div style={{ display: "flex" }}>
            <Typography variant="subtitle2" gutterBottom color="textSecondary">
              {"First Name"}
              <span style={{ color: "red", marginLeft: "2px" }}>*</span>
            </Typography>
          </div>

          <TextField
            id="selectQType"
            variant="outlined"
            size="small"
            name="First Name"
            placeholder="First Name"
            fullWidth
            margin="none"
            onChange={(e) => updateState("firstName", e.target.value)}
            value={state?.firstName ?? ""}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <div style={{ display: "flex" }}>
            <Typography variant="subtitle2" gutterBottom color="textSecondary">
              {"Middle Name"}
              {/* <span style={{ color: "red", marginLeft: "2px" }}>*</span> */}
            </Typography>
          </div>

          <TextField
            id="selectQType"
            variant="outlined"
            size="small"
            name="MiddleName"
            placeholder="Middle Name"
            fullWidth
            margin="none"
            onChange={(e) => updateState("middleName", e.target.value)}
            value={state?.middleName ?? ""}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <div style={{ display: "flex" }}>
            <Typography variant="subtitle2" gutterBottom color="textSecondary">
              {"Last Name"}
              <span style={{ color: "red", marginLeft: "2px" }}>*</span>
            </Typography>
          </div>

          <TextField
            id="LastName"
            variant="outlined"
            size="small"
            name="LastName"
            placeholder="Last Name"
            fullWidth
            margin="none"
            onChange={(e) => updateState("lastName", e.target.value)}
            value={state?.lastName ?? ""}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Typography variant="subtitle2" gutterBottom color="textSecondary">
            {"Gender"}
            <span style={{ color: "red", marginLeft: "2px" }}>*</span>
          </Typography>
          <MuiSelect
            id="selectClientType22"
            className="basic-single"
            classNamePrefix="select"
            value={prefixesList?.g?.find((l) => l.coding._id === state.gender)}
            placeholder="Select Type"
            getOptionLabel={(option) => option?.coding?.display ?? ""}
            handleselect={(data) =>
              updateState("gender", data?.coding?._id ?? "")
            }
            // label={"coding"}
            option={prefixesList?.g ?? []}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <div style={{ display: "flex" }}>
            <Typography variant="subtitle2" gutterBottom color="textSecondary">
              {"Email"}
              <span style={{ color: "red", marginLeft: "2px" }}>*</span>
            </Typography>
          </div>

          <TextField
            id="email"
            variant="outlined"
            size="small"
            name="email"
            placeholder="E-mail"
            fullWidth
            margin="none"
            onChange={(e) => updateState("email", e.target.value)}
            value={state?.email ?? ""}
          />
        </Grid>
      </Grid>
      {/* <Grid>
        <Box
          style={{
            marginTop: "8px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => onSaveClicked()}
            // className={classes.button}
            disabled={prefixesList?.r ? false : true}
          >
            {"Save"}
          </Button>
        </Box>
      </Grid> */}
    </Box>
  );
};
