import React from 'react'
import { SvgIcon, } from '@material-ui/core'


export function Phone(props) {

    return (
        <SvgIcon viewBox="0 0 9.62 14.921" style={{ fontSize: '1rem', marginRight: "8px" }}>
            <path d="M13.538,4A2.454,2.454,0,0,0,11,6.338V18.663A2.454,2.454,0,0,0,13.538,21h6.923A2.454,2.454,0,0,0,23,18.663V6.338A2.454,2.454,0,0,0,20.462,4H13.538Zm0,1.275h.727l.27.5a.7.7,0,0,0,.619.353h3.692a.7.7,0,0,0,.619-.353l.27-.5h.727a1.1,1.1,0,0,1,1.154,1.063V18.663a1.1,1.1,0,0,1-1.154,1.062H13.538a1.1,1.1,0,0,1-1.154-1.062V6.338A1.1,1.1,0,0,1,13.538,5.275Z" transform="translate(-11 -5)" fill="#4448C2" />
        </SvgIcon>
    )
}