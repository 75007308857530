import {
  Box,
  Grid,
  InputBase,
  makeStyles,
  MenuItem,
  Paper,
  styled,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "pfb-binder";
import { MuiSelect } from "../../../components/muiSelect/MuiSelect";
import { AddAddress } from "./clientDetails/addEditAddress";
import { ContactDetail } from "./clientDetails/contactDetails";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  gridItem: {
    padding: theme.spacing(2),
    "& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button":
      {
        margin: 0,
        display: "none",
      },
  },
  textResponsive: {
    // fontWeight: "600",
    fontSize: "1rem",
    flex: "1 1 auto",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem !important",
    },
  },
  heading: {
    margin: "8px",
  },
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: "inherit",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

export const ClientDetails = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { state, updateState = () => false, isEdit, editData } = props;
  const clientType = useSelector((state) => state?.codingMaster?.getClientType);
  const Use = useSelector((state) => state?.codingMaster?.getUseMaster);
  const contactMode = useSelector(
    (state) => state?.codingMaster?.getContactMode
  );

  React.useEffect(() => {
    getCodingMaster();
    //eslint-disable-next-line
  }, []);

  const getCodingMaster = async () => {
    await dispatch(actions.GET_CODING_MASTER({ state: "Client Type" }));
    await dispatch(actions.GET_USE_MASTER({ state: "USE" }));
    await dispatch(actions.GET_CONTACT_MODE({ state: "CONTACTSYSTEM" }));
  };

  // console.log(editData);
  return (
    <Paper
      style={{
        padding: "10px",
        // background: "#FFFFFF 0% 0% no-repeat padding-box",
        // opacity: 1,
      }}
      elevation={0}
    >
      <Grid
        container
        direction="row"
        display="flex"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
          <div style={{ display: "flex" }}>
            <Typography variant="subtitle2" gutterBottom color="textSecondary">
              {"Client Name"}
              <span style={{ color: "red", marginLeft: "2px" }}>*</span>
            </Typography>
          </div>

          <TextField
            id="selectQType"
            variant="outlined"
            size="small"
            name="ClientName"
            placeholder="Client Name"
            disabled={state?._key ? true : false}
            fullWidth
            margin="none"
            onChange={(e) => updateState("client_name", e.target.value)}
            value={state?.client_name ?? ""}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
          <Typography variant="subtitle2" gutterBottom color="textSecondary">
            {"Client Type"}
            <span style={{ color: "red", marginLeft: "2px" }}>*</span>
          </Typography>
          <MuiSelect
            id="selectClientType"
            className="basic-single"
            classNamePrefix="select"
            value={clientType?.data?.find(
              (l) => l.coding._id === state.client_type
            )}
            placeholder="Select Type"
            getOptionLabel={(option) => option?.coding?.display}
            handleselect={(data) =>
              updateState("client_type", data?.coding?._id ?? "")
            }
            // label={"coding"}
            option={clientType?.data ?? []}
          />
        </Grid>
      </Grid>
      {/* <Box className={classes.heading}>
        <Typography
          variant="body1"
          gutterBottom
          className={classes.textResponsive}
        >
          Contact Details
        </Typography>
      </Box> */}
      <ContactDetail
        state={state}
        updateState={updateState}
        isEdit={isEdit}
        contactMode={contactMode}
        Use={Use}
        editData={editData}
      />
      {/* <Box className={classes.heading}>
        <Typography variant="body1" className={classes.textResponsive}>
          Address
        </Typography>
      </Box> */}
      <AddAddress
        state={state}
        updateState={updateState}
        isEdit={isEdit}
        Use={Use}
        editData={editData}
      />
    </Paper>
  );
};
