import { Grid } from "@material-ui/core";
import React from "react";
import { idmapis } from "pfb-binder";
// import { async } from "taskviewersurveillance";
import { withNavBars } from "../../HOCs";
import ManageFacility from "../manageFacility";

function ViewOrganization(props) {
  const [Client_ID, setClient_ID] = React.useState();

  React.useEffect(() => {
    getClientId();
  }, []);

  const getClientId = async (tenentid) => {
    let Client_ID = await idmapis.getclientif_from_tenantid(
      localStorage.getItem("tenentid")
    );
    let _id = Client_ID.result[0]?._id;
    setClient_ID(_id);
  };

  return (
    <>
      {Client_ID && (
        <ManageFacility
          disableAddNew={true}
          clientId={Client_ID}
          tenantid={localStorage.getItem("tenentid")}
          view={true}
        />
      )}
    </>
  );
}

export default withNavBars(ViewOrganization);
