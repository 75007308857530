import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React from "react";
import RequiredField from "../common/requiredField";
import DateFnsUtils from "@date-io/date-fns";

function Dailogdatefilter({
  handleSubmit = () => false,
  handleClose = () => false,
  handlechangefordate = () => false,
  setOpen,
  setFromDate,
  setToDate,
  setShowFrom,
  setShowTo,
  open,
  state = {},
}) {
  
  console.log("open" , open)
  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={open}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">Filter</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1}>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              justifyContent="space-between"
              spacing={1}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Typography style={{ color: "black" }}>
                    From Date
                    <RequiredField color={"red"} />
                  </Typography>

                  <KeyboardDatePicker
                    fullWidth
                    size="small"
                    value={state?.fromDate ?? null}
                    placeholder="dd-mm-yyyy"
                    onChange={(date) => {
                      handlechangefordate(date, "from");
                      setFromDate(date);
                      setShowFrom(date);
                    }}
                    format="dd/MM/yyyy"
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Typography style={{ color: "black" }}>
                    To Date
                    <RequiredField color={"red"} />
                  </Typography>

                  <KeyboardDatePicker
                    fullWidth
                    size="small"
                    value={state?.toDate ?? null}
                    placeholder="dd-mm-yyyy"
                    onChange={(date) => {
                      handlechangefordate(date, "to");
                      setToDate(date);
                      setShowTo(date);
                    }}
                    format="dd/MM/yyyy"
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="outlined" onClick={handleSubmit}>
          submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Dailogdatefilter;
