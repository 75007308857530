/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-01-28
 * @modify date 2021-06-22
 * @desc Linear Progress Bar
 */

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
// import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";

function LinearProgressWithLabel(props) {
  // console.log("props", props);
  const classes = useStyles(props);

  return (
    <Box display="flex" alignItems="center" position="relative">
      <Box width="100%">
        <LinearProgress
          {...props}
          style={{
            height: 6,
            // background: '#DDDDDD',
            width: "100%",
            // ...(props.color && { color: props.color }),
          }}
          variant="determinate"
          // className={classes.bar}
          // color={props.color}
          value={Math.round((props.value / props.total) * 100)}
        />
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  linearRoot: {
    width: "100%",
  },
  bar: {
    height: 6,
    // background: '#DDDDDD',
    width: "100%",
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "#DDDDDD",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "green",
    },
  },
});

export default function LinearWithValueLabel({ progress, total, color }) {
  const classes = useStyles();

  return (
    <div className={classes.linearRoot}>
      <LinearProgressWithLabel total={total} value={progress} />
    </div>
  );
}
