import React from 'react'
import { SvgIcon, } from '@material-ui/core'


export function ContactPerson(props) {

    return (
        <SvgIcon viewBox="0 0 9.62 14.921" style={{ fontSize: '1rem', marginRight: "8px" }}>

            <g transform="translate(0)">
                <path id="Contact_Person_Name" data-name="Contact Person Name" d="M6.75,5a.439.439,0,1,0,0,.878H6.9V11.45A1.034,1.034,0,0,0,6,12.463v3.219a.444.444,0,0,0,.45.439h3.9a.444.444,0,0,0,.45-.439v-1.61H12v1.61a.444.444,0,0,0,.45.439h.564a1.6,1.6,0,0,1-.114-.585v-1.9a.444.444,0,0,0-.45-.439h-2.1a.444.444,0,0,0-.45.439v1.61h-3v-2.78a.147.147,0,0,1,.15-.146h.3a.444.444,0,0,0,.45-.439v-6H15v4.537a2,2,0,0,1,.75-.146c.051,0,.1,0,.15.006v-4.4h.15a.439.439,0,1,0,0-.878ZM9,7.049a.3.3,0,0,0-.3.293v.585a.3.3,0,0,0,.3.293h.6a.3.3,0,0,0,.3-.293V7.341a.3.3,0,0,0-.3-.293Zm2.1,0a.3.3,0,0,0-.3.293v.585a.3.3,0,0,0,.3.293h.6a.3.3,0,0,0,.3-.293V7.341a.3.3,0,0,0-.3-.293Zm2.1,0a.3.3,0,0,0-.3.293v.585a.3.3,0,0,0,.3.293h.6a.3.3,0,0,0,.3-.293V7.341a.3.3,0,0,0-.3-.293ZM9,9.1a.3.3,0,0,0-.3.293v.585a.3.3,0,0,0,.3.293h.6a.3.3,0,0,0,.3-.293V9.39A.3.3,0,0,0,9.6,9.1Zm2.1,0a.3.3,0,0,0-.3.293v.585a.3.3,0,0,0,.3.293h.6a.3.3,0,0,0,.3-.293V9.39a.3.3,0,0,0-.3-.293Zm2.1,0a.3.3,0,0,0-.3.293v.585a.3.3,0,0,0,.3.293h.6a.3.3,0,0,0,.3-.293V9.39a.3.3,0,0,0-.3-.293Zm2.55,1.756a1.317,1.317,0,1,0,1.35,1.317,1.334,1.334,0,0,0-1.35-1.317ZM9,11.146a.3.3,0,0,0-.3.293v.585a.3.3,0,0,0,.3.293h.6a.3.3,0,0,0,.3-.293v-.585a.3.3,0,0,0-.3-.293Zm2.1,0a.3.3,0,0,0-.3.293v.585a.3.3,0,0,0,.3.293h.6a.3.3,0,0,0,.3-.293v-.585a.3.3,0,0,0-.3-.293Zm2.1,0a.3.3,0,0,0-.3.293v.585a.3.3,0,0,0,.3.293h.606c0-.047-.006-.1-.006-.146a1.862,1.862,0,0,1,.237-.907v0a.3.3,0,0,0-.237-.114ZM8.1,13.195a.3.3,0,0,0-.3.293v.585a.3.3,0,0,0,.3.293h.6a.3.3,0,0,0,.3-.293v-.585a.3.3,0,0,0-.3-.293Zm6.3.878a.889.889,0,0,0-.9.878v.585c0,.808,1.007,1.463,2.25,1.463S18,16.345,18,15.536v-.585a.889.889,0,0,0-.9-.878Z" transform="translate(-6 -5)"
                    fill="#4448C2"
                />
            </g>
        </SvgIcon>
    )
}