import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import { TbLogout } from "react-icons/tb";
import { DrawerContext } from "../../contexts";
import { DrawerProps } from "../../utils";
import Badge from "@material-ui/core/Badge";
import Config from "../../config";
import {
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
  Tooltip,
  Divider,
  Avatar,
} from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory } from "react-router-dom";
import ListNotification from "./listNotification";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: theme.palette.appBar.main,
    boxShadow: "unset",
    // borderBottom: "1px solid #e0e0e0",
  },
  title: {
    display: "block",

    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem !important",
    },
  },
  caption: {
    display: "block",
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.6rem !important",
    },
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  menuIcon: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  userProfilecard: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  profilePic: {
    marginLeft: "8px",
  },
  notificationdiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  notifications: {},
}));

export const TopNavBar = (props) => {
  const { user } = props;
  const classes = useStyles();
  const history = useHistory();
  const Theme = useTheme();
  const sm = useMediaQuery(Theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [notificationMsg, setNotificationMsg] = React.useState([]);
  const[loginuser,setLoginUser] = React.useState({});
  const id = open ? "simple-popover" : undefined;
  const drawerContext = React.useContext(DrawerContext);
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  React.useEffect(() => {
    
    getPersonLoggedin(
      localStorage.getItem("keyclkId"),
      atob(localStorage.getItem("RBAC"))
    );
    notify();
    const interval = setInterval(
      () => notify(),
      Number(localStorage.getItem("TriggerTiming") ?? "300000")
    );
    return () => {
      clearInterval(interval);
    };
  }, []);
  const handleClose = () => {
    setAnchorEl(null);
  };
    // React.useLayoutEffect(() => {
     
      
      
    // }, []);
    
   
    // const getPersonLoggedin = async (id, role) => {
    //   // debugger;
    //   let params = {
    //     db_name: Config.dbname,
    //     entity: process.env.REACT_APP_ENTITY_PERSON,
    //     filter: `${process.env.REACT_APP_ENTITY_PERSON}.keycloackid=='${id}' && ${process.env.REACT_APP_ENTITY_PERSON}.activestatus==true`,
    //     return_fields:
    //     " {rolename:Person.roleid[0].rolename,person:UNSET(document(Person.name[0]),'_key','_id','_rev','createddate','createdby','activestatus','updatedby','updatedate','family','id','given','prefix','use','person','period')}",
    //   };

    //   let res = await AxiosCall(
    //     "post",
    //     `${Config.api_url}/read_documents`,
    //     params
    //   );
    //   let result = res?.result[0];
    //   setLoginUser(result);
    // };

  const handleLogout = () => {
    localStorage.removeItem("queue_token");
    localStorage.removeItem("permissionData");
    localStorage.removeItem("keyclkId");
    localStorage.removeItem("RoleId");
    localStorage.removeItem("TriggerTiming");
    localStorage.removeItem("access_token");
    localStorage.removeItem("person_id");
    localStorage.removeItem("rolename");
    localStorage.removeItem("RoleId")
    history.push("/");
  };
  const notify = async () => {
    // debugger;
    let notifierid = localStorage.getItem("person_id");
    let myHeaders = new Headers();
    myHeaders.append("content-type", "application/json");
    fetch(`${Config.api_url}/read_documents`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        db_name: Config.dbname,
        entity: "Notification",
        filter: `Notification.activestatus==true && Notification.notifier_id == '${notifierid}' `,
        return_fields:
          "keep(Notification,'_id','Notification_id','Notification','notifier_id','ticketId','patient_id','client_id','form_id','is_active','createddate')",
        sort: "Notification.createddate desc",
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        let Res = [...result.result];
        //   result.result.forEach((e)=>{
        //     Res.push({
        //       image :'' ,
        //       message : e.Notification,
        //       // detailPage : "",
        //       receivedTime:'12h ago',
        //       Notification_count:e.Notification_count,
        //       _id:e._id
        //     })
        //     setNotificationMsg(Res)
        // console.log(Res);
        //   })
        // console.log(Res);
        setNotificationMsg(Res);
      })
      .catch((err) => console.log(err));
  };

  const getPersonLoggedin = async (id, role) => {
    //debugger; 
    let myHeaders = new Headers();
    myHeaders.append("content-type", "application/json");
    fetch(`${Config.api_url}/read_documents`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        db_name: Config.dbname,
        entity: "Person",
        // filter: `${process.env.REACT_APP_ENTITY_PERSON}.keycloackid=='${id}' && ${process.env.REACT_APP_ENTITY_PERSON}.activestatus==true`,
        filter: `Person.activestatus==true && Person.keycloackid=='${id}' `,
        return_fields:
        " {rolename:Person.roleid[0].rolename,person_id:Person._id,person:UNSET(document(Person.name[0]),'_key','_id','_rev','createddate','createdby','activestatus','updatedby','updatedate','family','id','given','prefix','use','person','period')}",
       // sort: "Notification.createddate desc",
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("Result",result)
        let Res = [...result.result];
        //   result.result.forEach((e)=>{
        //     Res.push({
        //       image :'' ,
        //       message : e.Notification,
        //       // detailPage : "",
        //       receivedTime:'12h ago',
        //       Notification_count:e.Notification_count,
        //       _id:e._id
        //     })
        //     setNotificationMsg(Res)
        // console.log(Res);
        //   })
        
        setLoginUser(Res[0]);
        localStorage.setItem("rolename", Res[0].rolename);
        localStorage.setItem("person_id", Res[0].person_id);
        // console.log("Person:", Res)
        // console.log("Rolename", localStorage.getItem("rolename"))
       
      })
      .catch((err) => console.log(err));
  };

  const onNotifyclick = (data) => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: <ListNotification data={data} countnotify={notify} />,
      onClose: () => onDrawerClosed(data),
    });
  };
  const onDrawerClosed = async (data) => {
    // debugger;
    drawerContext.setDrawer({
      ...drawerContext,
      open: false,
      direction: DrawerProps.direction.right,
    });
  };
  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <div className={classes.titleContainer}>
            <div>
              <Typography className={classes.title} variant="body1" noWrap>
                {"Assessment Platform"}
              </Typography>
              <Typography variant="caption" className={classes.caption}>
                {`v ${localStorage.getItem("version")}`}
              </Typography>
            </div>
          </div>

          <div className={classes.grow} />
          <div className={classes.notificationdiv}>
        
          {  ((localStorage.getItem("rolename"))?.toString()?.toLowerCase()?.includes("notifi") ) && (
          <IconButton
                onClick={() => onNotifyclick(notificationMsg)}
                style={{ color: "#fff" }}
              >
                <Badge
                  badgeContent={
                    notificationMsg.filter((l) => l?.is_active === true)
                      .length
                   // notificationMsg.length
                  }
                  color="secondary"
                >
                  <NotificationsNoneOutlinedIcon />
                </Badge>
              </IconButton>)}
          </div>

          {/* <Avatar className={classes.profilePic}>
            {user.charAt(0).toUpperCase()}
          </Avatar> */}
          <div className={classes.userProfilecard}>
            <div>
              <Typography
                className={classes.title}
                variant="body1"
                component={"h6"}
                noWrap
              >
                {user?.trim().length > 0 && user}
              </Typography>
              {/* {user?.person?.text?.trim().length > 0 && (
                <Typography
                  // className={classes.title}
                  variant="caption"
                  noWrap
                >
                  {user?.rolename}
                </Typography>
              )} */}
            </div>
          </div>

          <Divider
            flexItem
            orientation="vertical"
            style={{ margin: "16px 4px", width: "2px", background: "#fff" }}
          />

          {sm ? (
            <IconButton
              style={{ color: "#fff" }}
              onClick={() => handleLogout()}
            >
              <TbLogout />
            </IconButton>
          ) : (
            <Tooltip title="Logout" arrow>
              <IconButton
                style={{ color: "#fff" }}
                onClick={() => handleLogout()}
              >
                <TbLogout />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
      </AppBar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List>
          <ListItem Button onClick={() => handleLogout()}>
            <ListItemIcon style={{ width: "30px" }}>
              <ExitToAppIcon />
            </ListItemIcon>
            {!sm && <ListItemText primary="Logout" />}
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};
