import React from "react";
import { makeStyles } from "@material-ui/core";

import {
  TableComponent,
  SubHeader,
  LoadingSection,
  InternatlServerError,
  NotFoundSection,
  CaseDefinition,
} from "../../components";
import { useHistory } from "react-router-dom";
import { Message, DrawerProps } from "../../utils";
import { Routes } from "../../router/routes";
import { DrawerContext } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  backBtn: {
    paddingBottom: theme.spacing(2),
  },
}));

export const NotificationDetail = (props) => {
  const classes = useStyles(props);

  const history = useHistory();

  let { data = [], loading, error } = props;

  const drawerContext = React.useContext(DrawerContext);

  const onInfoClicked = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: <CaseDefinition data={data?.form_detail?.mapped_diseases} />,
      onClose: () => onDrawerClosedLeft(),
    });
  };

  const onDrawerClosedLeft = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: false,
      direction: DrawerProps.direction.right,
    });
  };

  const onBackBtnClicked = () => {
    history.push(Routes.notify);
  };

  return (
    <div className={classes.root}>
      {/* Back Button */}
      <SubHeader
        title={
          loading && !data?.form_detail?.form_name
            ? Message.form_detail_loading
            : `${data?.form_detail?.form_name ?? "Go Back"} (${
                data?.row?.length ?? 0
              })`
        }
        onBackBtnClicked={onBackBtnClicked}
        onActionIconBtnClicked={onInfoClicked}
        showInfoButton
      />

      {loading && !error && !data?.form_detail?.form_name && (
        <LoadingSection message={Message.notification_detail_fetching} />
      )}
      {!loading && error && !data?.form_detail?.form_name && (
        <InternatlServerError
          message={Message.notification_detail_not_found}
          onRetry={props.getNotifiedEntryByFormId}
        />
      )}
      {!loading &&
        !error &&
        data?.form_detail?.form_name &&
        data?.row?.length === 0 && (
          <NotFoundSection message={Message.notification_detail_not_found} />
        )}
      {!loading && !error && !data?.form_detail?.form_name && (
        <NotFoundSection />
      )}

      {data?.form_detail?.form_name && data?.row?.length > 0 && (
        <div>
          <TableComponent data={props.data} />
        </div>
      )}
    </div>
  );
};
