import { Typography } from "@material-ui/core";
import {
  Button,
  LinearProgress,
  Divider,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { TabSelect, Mobile, DOB, LocationLevel } from "../../components";
import { PatientDetailCard } from "../../components/patientDetailCard";
import { AlertProps, genderData, personalTitles } from "../../utils/constants";
import { ComponentToRender } from "./componentToRender";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { AlertContext } from "../../contexts";
import { actions, idmapis } from "pfb-binder";
import { ValidateEmail } from "../../utils";
import jwt_decode from "jwt-decode";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 16,
    // borderBottom: `1px solid ${theme.palette.divider}`,
    background: "white",
    // color: theme.palette.primary.main,
    zIndex: 99,
  },
  searchResultSection: {
    // borderLeft: `1px solid ${theme.palette.divider}`,
    height: "calc(100vh - 210px)",
    overflow: "scroll",
  },
  fieldsSection: {
    padding: 16,
    height: "calc(100vh - 210px)",
    overflow: "scroll",
    overflowX: "hidden",
  },
  choosPatientHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 8,
    padding: 16,
    // borderBottom: `1px solid ${theme.palette.divider}`,
  },
  patientDetailCard: {
    padding: 16,
  },
  hash: {
    color: "#83859B",
  },
  errorStyle: {
    color: "#FF0000",
    fontSize: "13px",
  },
}));
const optionsList = [
  { value: "Passport Number", label: "Passport Number" },
  { value: "Patient ID", label: "Patient ID" },
  { value: "Iqama", label: "Iqama" },
];
export const PatientDetail = ({
  patientDetail = {},
  isEdit = false,
  onPatientSelected = () => false,
}) => {
  const [state, setState] = useState({
    IdType: {
      value: "",
      label: "",
    },
    ID: "",
    patient_name: {
      title: {
        value: "",
        label: "",
      },
      first_name: "",
      middle_name: "",
      last_name: "",
    },
    gender: "",
    dob_age: {
      dob: "",
      age: 0,
    },
    mobile: {
      phoneCode: "+966",
    },
    email: "",
    addressline: "",
    IdType: optionsList[2],
    country: "",
  });
  const [errorss, setErrors] = useState({
    idtype: "",
    id: "",
    country: "",
    FirstName: "",
    Gender: "",
    DOB: "",
    MobileNumber: "",
  });
  const [isPatientDisabled, setIsPatientDisabled] = useState({
    Title: false,
    FirstName: false,
    MiddleName: false,
    LastName: false,
    IDType: false,
    ID: false,
    Country: false,
    Gender: false,
    DOB: false,
    Address: false,
    MailID: false,
    MobileNo: false,
    ClientID: "",
  });

  useEffect(() => {
    const data = {
      phoneCode: "+966",
      number: "",
    };
    state["mobile"] = data;
    state["country"] = citizenData?.data[1];
    setState({
      ...state,
    });
  }, []);
  const alert = useContext(AlertContext);
  const personId = jwt_decode(localStorage.getItem("queue_token"));
  const classes = useStyles();
  const dispatch = useDispatch();

  const patientData = useSelector(
    (state) => state?.patientSlice?.getAllPatient
  );

  const patientView = useSelector(
    (state) => state?.patientSlice?.getPatientView
  );

  const upsertPatientData = useSelector(
    (state) => state?.patientSlice?.upsertPatient
  );

  // const countryData = useSelector(
  //     state => state?.countrySlice?.getAllCountries
  // );

  const citizenData = useSelector(
    (state) => state?.countrySlice?.getAllCitizen
  );
  const DobToAge = (date) => {
    if (date !== null) {
      // console.log("date", date);
      let DOB = +new Date(date);
      let age = (Date.now() - DOB) / 31557600000;
      return Math.floor(age);
    }
    // setPatientDetails({ ...patientDetails, age: age });
  };

  const changeState = (key, value) => {
    // debugger;
    state[key] = value;

    setState({
      ...state,
    });
    if (key === "email") {
      emailHandler(value);
    }
    // if (key === "ID") {
    //   debugger;
    //   IDValidation(value);
    // }
    checkValidity(key, value);
    searchPatient();
  };

  const checkValidity = (key, value) => {
    switch (key) {
      case "ID": {
        if (!value) {
          if (isPatientDisabled?.ID) {
            setErrors({ ...errorss, id: "id is required" });
          } else {
            setErrors({ ...errorss, id: "" });
          }
        } else {
          IDValidation(value)
            ? setErrors({ ...errorss, id: "" })
            : setErrors({ ...errorss, id: "Invalid id" });
          // setErrors({ ...errorss, id: "" });
        }
        break;
      }
      case "IdType": {
        if (!value) {
          if (isPatientDisabled?.IDType) {
            setErrors({ ...errorss, idtype: "iD Type is required" });
          } else {
            setErrors({ ...errorss, idtype: "" });
          }
        } else {
          setErrors({ ...errorss, idtype: "" });
        }
        break;
      }
      case "country": {
        if (!value) {
          if (isPatientDisabled?.Country) {
            setErrors({ ...errorss, country: "ID Type is required" });
          } else {
            setErrors({ ...errorss, country: "" });
          }
        } else {
          setErrors({ ...errorss, country: "" });
        }
        break;
      }
      case "patient_name": {
        if (!value) {
          if (isPatientDisabled?.FirstName) {
            setErrors({ ...errorss, FirstName: "First Name is required" });
          } else {
            setErrors({ ...errorss, FirstName: "" });
          }
        } else {
          setErrors({ ...errorss, FirstName: "" });
        }
        break;
      }
      case "gender": {
        if (!value) {
          if (isPatientDisabled?.Gender) {
            setErrors({ ...errorss, Gender: "Gender is required" });
          } else {
            setErrors({ ...errorss, Gender: "" });
          }
        } else {
          setErrors({ ...errorss, Gender: "" });
        }
        break;
      }
      case "dob_age": {
        if (!value) {
          if (isPatientDisabled?.DOB) {
            setErrors({ ...errorss, DOB: "DOB is required" });
          } else {
            setErrors({ ...errorss, DOB: "" });
          }
        } else {
          setErrors({ ...errorss, DOB: "" });
        }
        break;
      }
      case "mobile": {
        if (!value) {
          if (isPatientDisabled?.MobileNo) {
            setErrors({
              ...errorss,
              MobileNumber: "Mobile Number is required",
            });
          } else {
            setErrors({ ...errorss, MobileNumber: "" });
          }
        } else {
          setErrors({ ...errorss, MobileNumber: "" });
        }
        break;
      }
    }
  };

  const [error, seterror] = useState(false);
  const [message, setMessage] = useState([]);
  const [mobileValid, setMobileValid] = React.useState(false);
  const [idValid, setIdValid] = React.useState(false);
  const [client_ID, setClient_ID] = React.useState("");
  React.useEffect(() => {
    (async () => {
      let Client_ID = await idmapis.getclientif_from_tenantid(
        localStorage.getItem("tenentid")
      );
      setClient_ID(Client_ID?.result[0]?._id);
      let res = await dispatch(
        actions.GET_PATIENTS_VIEW({ client_id: Client_ID.result[0]?._id })
      );
      let payload = res?.payload?.data;

      if (payload?.length > 0) {
        setIsPatientDisabled((prev) => {
          return { ...prev, ...payload[0] };
        });
      } else {
        setIsPatientDisabled((prev) => {
          return {
            Title: true,
            FirstName: true,
            MiddleName: true,
            LastName: true,
            IDType: true,
            ID: true,
            Country: true,
            Gender: true,
            DOB: true,
            Address: true,
            MailID: true,
            MobileNo: true,
            ClientID: "",
          };
        });
      }
    })();
  }, []);
  //console.log("patientView:", patientView);
  //console.log("isPatientDisabled:", isPatientDisabled);
  const emailHandler = (value) => {
    let validation = ValidateEmail(value) ? true : false;
    if (!validation && value.length > 0) {
      seterror(true);
    } else {
      seterror(false);
    }
  };
  console.log(idValid);
  const IDValidation = (value) => {
    //debugger;
    let valid = false;
    let idtype = state?.IdType?.value;
    if (idtype === "Iqama") {
      if (isNaN(value)) {
        valid = false;
      } else {
        let numtotext = value.toString();
        //console.log(numtotext.length, numtotext.length === 10);
        if (numtotext.length === 10) {
          if (numtotext.startsWith("1") || numtotext.startsWith("2")) {
            valid = true;
          } else {
            valid = false;
          }
        } else {
          valid = false;
        }
      }
    } else {
      valid = true;
    }
    return valid;
  };
  const mobileNumberError = (val) => {
    setMobileValid(val);
  };
  const searchPatient = () => {
    let params = {
      filter: buildPatientSearchFilter(),
    };

    Promise.resolve(dispatch(actions.GET_ALL_PATIENTS(params)));
  };

  const buildPatientSearchFilter = () => {
    let filter = "";

    if (state?.ID) {
      filter += ` && patient.ID like "${state?.ID}%" && patient.client_ID like "${client_ID}" `;
    }
    if (state?.patient_name?.first_name) {
      filter += ` && LOWER(patient.patient_name.first_name) like "${state?.patient_name?.first_name.toLowerCase()}%" && patient.client_ID like "${client_ID}"`;
    }
    if (state?.mobile?.number) {
      filter += ` && patient.mobile.number like "${state?.mobile?.number}%" && patient.client_ID like "${client_ID}"`;
    }

    if (state?.email) {
      filter += ` && UPPER(patient.email) like "${state?.email.toUpperCase()}%" && patient.client_ID like "${client_ID}"`;
    }

    state?.country?.patient_details?.components?.forEach((comp) => {
      if (state?.[comp.id]) {
        if (
          ["input_text", "input_textarea", "input_number"].indexOf(
            comp.component
          ) > -1
        ) {
          filter += ` && UPPER(patient["${comp.id}"]) like "${
            state?.[comp.id]
          }%" && patient.client_ID like "${client_ID}"`;
        }
      }
    });

    return filter;
  };

  const giveMeProperQuestion = (comp) => {
    return {
      ...comp,
      question_id: comp.id,
      ui_component: comp.component,
      is_required: comp.required,
      question: comp.label,
      question_options: comp.options,
      table_scheme: comp.tableScheme,
    };
  };
  const isValidToInsert = () => {
    let erroridtype = "";
    let errorid = "";
    let errorcountry = "";
    let errorFirstName = "";
    let errorGender = "";
    let errorDOB = "";
    let errorMobileNumber = "";

    let isValid = true;

    let Validfirstname = isPatientDisabled?.FirstName
      ? state?.patient_name?.first_name?.trim().length === 0 ||
        !state.hasOwnProperty("patient_name")
        ? true
        : false
      : false;

    let valididtype = isPatientDisabled?.IDType
      ? state?.IdType?.value?.length === 0 || !state.hasOwnProperty("IdType")
        ? true
        : false
      : false;

    let validid = isPatientDisabled?.ID
      ? state?.ID?.length === 0 || !state.hasOwnProperty("ID")
        ? true
        : !IDValidation(state?.ID)
        ? true
        : false
      : false;
    let validgender = isPatientDisabled?.Gender
      ? state?.gender?.length === 0 || !state.hasOwnProperty("gender")
        ? true
        : false
      : false;
    let validmobilenumber = isPatientDisabled?.MobileNo
      ? state?.mobile?.number?.toString()?.length === 0 ||
        !state.hasOwnProperty("mobile") ||
        state?.mobile?.number?.toString()?.length !== mobileValid
        ? true
        : false
      : false;
    let validdob = isPatientDisabled?.DOB
      ? state?.dob_age?.age?.toString()?.length === 0 ||
        !state.hasOwnProperty("dob_age")
        ? true
        : false
      : false;
    let validcountry = isPatientDisabled?.Country
      ? state?.country?.value?.length === 0 || !state.hasOwnProperty("country")
        ? true
        : false
      : false;
    if (
      //   !state?.patient_name?.title?.value ||
      Validfirstname ||
      valididtype ||
      validid ||
      validgender ||
      validmobilenumber ||
      validdob ||
      validcountry ||
      // validemail ||
      //   Validmiddlename ||
      //   Validlastname ||
      //   !state?.gender ||
      //   !state?.mobile?.number ||
      // !state?.email ||
      //   !state?.dob_age?.dob ||
      //   !state?.addresline ||
      // validaddress ||
      error
      //   !state?.address?.country
      // !state?.country?.value
    ) {
      isValid = false;
    }
    if (isPatientDisabled?.Country) {
      for (
        let index = 0;
        index < state?.country?.patient_details?.components?.length ?? 0;
        index++
      ) {
        const comp = state?.country?.patient_details?.components?.[index];
        if (comp.required && !state?.[comp.id]) {
          isValid = false;
          break;
        }
      }
    }

    let messageerror = [];

    if (isValid == false) {
      if (isPatientDisabled?.FirstName) {
        if (Validfirstname) {
          errorFirstName = "First Name is Required";
          messageerror.push("FirstName");
        }
      }

      if (isPatientDisabled.IDType) {
        if (valididtype) {
          erroridtype = "Id Type is Required";
          messageerror.push("Id Type");
        }
      }
      if (isPatientDisabled?.ID) {
        debugger;
        if (validid) {
          let validID = IDValidation(state?.ID);
          if (!validID) {
            errorid = "Invalid ID";
            messageerror.push("Id");
          } else {
            errorid = "Id is Required";
            messageerror.push("Id");
          }
        }
      }

      if (isPatientDisabled?.Gender) {
        if (validgender) {
          errorGender = "Gender is Required";
          messageerror.push("gender");
        }
      }

      if (isPatientDisabled?.MobileNo) {
        if (validmobilenumber) {
          errorMobileNumber = "Mobile Number is Required";
          messageerror.push("mobile number");

          //setErrors({...errorss , Gender:"Mobile Number is Required" })
        }
      }
      if (isPatientDisabled?.DOB) {
        if (validdob) {
          errorDOB = "DOB is Required";
          messageerror.push("dob");
        }
      }
      if (isPatientDisabled?.Country) {
        if (validcountry) {
          errorcountry = "country is Required";
          //setErrors({...errorss , country:"country is Required" })
          messageerror.push("Country");
        }
      }

      if (
        erroridtype ||
        errorid ||
        errorcountry ||
        errorFirstName ||
        errorGender ||
        errorDOB ||
        errorMobileNumber
      ) {
        setErrors({
          idtype: erroridtype,
          id: errorid,
          country: errorcountry,
          FirstName: errorFirstName,
          Gender: errorGender,
          DOB: errorDOB,
          MobileNumber: errorMobileNumber,
        });
      }
      setMessage(messageerror);
    }
    return { status: isValid, messagevalue: messageerror };
  };

  const createPatientAndNavigate = async () => {
    const { status, messagevalue } = isValidToInsert();
    if (!status) {
      let errorMessage = "";

      if (messagevalue.length > 0) {
        let value = [...messagevalue];
        let text = value.toString();
        errorMessage = `${text} is Required`;
      } else {
        errorMessage = `Please Fill the mandatory fields`;
        // errorMessage = status;
      }
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.warning,
        msg: errorMessage,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
      return false;
    }

    let params = {
      ...state,
      personid: personId.personid,
    };

    onPatientSelected({
      ...state,
      personid: personId.personid,
      patient_id: null,
      createPatient: true,
      createPatientData: params,
      notify: false,
    });
  };

  const navigateToForm = () => {
    onPatientSelected({
      ...state.selectedPatient,
      patient_id: state.patient_id,
      notify: true,
    });
  };
  const onPatientSelectedFromList = (data, isSelected) => {
    if (isSelected) {
      setState({
        ...state,
        patient_id: data?.patient_id,
        selectedPatient: data,
      });
    } else {
      setState({
        ...state,
        patient_id: null,
        selectedPatient: null,
      });
    }
  };

  //console.log("state", state);
  return (
    <div className={classes.root}>
      <Grid container direction={"row"}>
        <Grid item xs={12} className={classes.header}>
          <Typography variant="body1">Patient Detail</Typography>
        </Grid>

        <Grid item xs={12} container direction={"row"}>
          <Grid item xs={12} md={8} className={classes.fieldsSection}>
            <Grid container direction="row" spacing={3}>
              {/* Basic Details */}
              {/* Patient Name */}

              {/* Patient Country */}
              {isPatientDisabled?.IDType ? (
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.hash}
                  >
                    ID Type{" "}
                    <Typography color="error" variant="caption">
                      *
                    </Typography>
                  </Typography>
                  <Select
                    id="country"
                    //   isLoading={citizenData?.loading}
                    isClearable
                    isSearchable
                    value={state?.IdType}
                    placeholder="Select ID Type"
                    options={optionsList}
                    defaultValue={optionsList[2]}
                    onChange={(value) => changeState("IdType", value)}
                  />
                  {errorss.idtype ? (
                    <p className={classes.errorStyle}>{errorss.idtype}</p>
                  ) : (
                    ""
                  )}
                </Grid>
              ) : null}
              {isPatientDisabled?.ID ? (
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.hash}
                  >
                    ID
                    <Typography color="error" variant="caption">
                      *
                    </Typography>
                  </Typography>

                  <TextField
                    id="ID"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={state?.ID ?? ""}
                    placeholder="ID"
                    onChange={(e) => {
                      changeState("ID", e.target.value);
                    }}
                  />
                  {errorss.id ? (
                    <p className={classes.errorStyle}>{errorss.id}</p>
                  ) : (
                    ""
                  )}
                </Grid>
              ) : null}
              {isPatientDisabled?.Country ? (
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.hash}
                  >
                    Choose citizen country{" "}
                    <Typography color="error" variant="caption">
                      *
                    </Typography>
                  </Typography>
                  <Select
                    id="country"
                    isLoading={citizenData?.loading}
                    isClearable
                    isSearchable
                    value={state?.country}
                    defaultValue={citizenData?.data[1]}
                    placeholder="Select Country"
                    options={
                      citizenData?.loading ? [] : citizenData?.data ?? []
                    }
                    onChange={(value) => changeState("country", value)}
                  />

                  {errorss.country ? (
                    <p className={classes.errorStyle}>{errorss.country}</p>
                  ) : (
                    ""
                  )}
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <Divider variant="fullWidth" />
                {/* <Typography variant="h6">Basic Patient Details</Typography> */}
              </Grid>

              {isPatientDisabled?.FirstName ? (
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.hash}
                  >
                    Patient Name{" "}
                    <Typography color="error" variant="caption">
                      *
                    </Typography>
                  </Typography>
                  <Grid container direction="row" spacing={2}>
                    {isPatientDisabled?.FirstName ? (
                      <Grid item xs={12} md={4}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          className={classes.hash}
                        >
                          First Name{" "}
                          <Typography color="error" variant="caption">
                            *
                          </Typography>
                        </Typography>
                        <TextField
                          id="firstName"
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={state?.patient_name?.first_name ?? ""}
                          //   placeholder="First Name"
                          onChange={(e) => {
                            const re = /^[a-zA-Z]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              changeState("patient_name", {
                                ...(state?.patient_name ?? {}),
                                first_name: e.target.value,
                              });
                            }
                          }}
                        />
                        {errorss.FirstName ? (
                          <p className={classes.errorStyle}>
                            {errorss.FirstName}
                          </p>
                        ) : (
                          ""
                        )}
                      </Grid>
                    ) : null}
                    {isPatientDisabled?.MiddleName ? (
                      <Grid item xs={12} md={4}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          className={classes.hash}
                        >
                          Middle Name{" "}
                        </Typography>
                        <TextField
                          id="MiddleName"
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={state?.patient_name?.middle_name ?? ""}
                          //  placeholder="Middle Name"
                          onChange={(e) => {
                            const re = /^[a-zA-Z]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              changeState("patient_name", {
                                ...(state?.patient_name ?? {}),
                                middle_name: e.target.value,
                              });
                            }
                          }}
                        />
                      </Grid>
                    ) : null}
                    {isPatientDisabled?.LastName ? (
                      <Grid item xs={12} md={4}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          className={classes.hash}
                        >
                          Last Name{" "}
                        </Typography>
                        <TextField
                          //   id="LastName"
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={state?.patient_name?.last_name ?? ""}
                          //   placeholder="Last Name"
                          onChange={(e) => {
                            const re = /^[a-zA-Z]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              changeState("patient_name", {
                                ...(state?.patient_name ?? {}),
                                last_name: e.target.value,
                              });
                            }
                          }}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              ) : null}

              {/* Gender */}
              {isPatientDisabled?.Gender ? (
                <Grid item xs={12} md={4}>
                  <Typography variant="body1" className={classes.hash}>
                    Gender{" "}
                    <Typography color="error" variant="caption">
                      *
                    </Typography>
                  </Typography>
                  <TabSelect
                    id="gender"
                    label={""}
                    value={state?.gender}
                    items={genderData}
                    onClick={(value) => changeState("gender", value)}
                    isReadyOnly={false}
                    errorValidation={true ?? {}}
                    disabled={false ?? false}
                  />
                  {errorss.Gender ? (
                    <p className={classes.errorStyle}>{errorss.Gender}</p>
                  ) : (
                    ""
                  )}
                </Grid>
              ) : null}

              {/* Date of Birth & Age */}
              {isPatientDisabled?.DOB ? (
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="body1"
                    className={classes.hash}
                    style={{ marginBottom: "11px" }}
                  >
                    Date of Birth / Age
                    <Typography color="error" variant="caption">
                      *
                    </Typography>
                  </Typography>

                  <DOB
                    id={"DOBFromPatientDetails"}
                    value={state?.dob_age}
                    onChange={(value) => changeState("dob_age", value)}
                    label={""}
                  />
                  {errorss.DOB ? (
                    <p className={classes.errorStyle}>{errorss.DOB}</p>
                  ) : (
                    ""
                  )}
                </Grid>
              ) : null}

              {/* Mobile No */}
              {isPatientDisabled?.MobileNo ? (
                <Grid item xs={12} md={8}>
                  <Typography variant="body1" className={classes.hash}>
                    Mobile No.{" "}
                    <Typography color="error" variant="caption">
                      *
                    </Typography>
                  </Typography>
                  <Mobile
                    idSelect={"mobileformdetailcountrycode"}
                    id={"mobileFromPatientDetails"}
                    value={state?.mobile}
                    label={""}
                    handleChange={(value) => changeState("mobile", value)}
                    type={"number"}
                    error={mobileNumberError}
                  />

                  {errorss.MobileNumber ? (
                    <p className={classes.errorStyle}>{errorss.MobileNumber}</p>
                  ) : (
                    ""
                  )}
                </Grid>
              ) : null}
            </Grid>
          </Grid>

          <Grid item xs={12} md={4} className={classes.searchResultSection}>
            {patientData?.loading && <LinearProgress />}
            {patientData?.data?.length > 0 &&
              (state?.email ||
                state?.mobile?.number ||
                state?.ID ||
                state?.patient_name?.first_name) && (
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  justify="space-between"
                >
                  <Grid item xs={12}>
                    <div className={classes.choosPatientHeader}>
                      <Typography variant="body1">
                        {"Choose Patient"}
                      </Typography>
                      {!state.patient_id && (
                        <Button
                          id="createPatient"
                          size="small"
                          variant="outlined"
                          color="primary"
                          disabled={upsertPatientData?.loading}
                          onClick={createPatientAndNavigate}
                        >
                          {upsertPatientData?.loading
                            ? "Creating Patient Record..."
                            : "Create Patient & Notify"}
                          <div style={{ marginLeft: 5, display: "flex" }}>
                            <ArrowRightAltIcon />
                          </div>
                        </Button>
                      )}
                      {state.patient_id && (
                        <Button
                          id="NotifySelected"
                          size="small"
                          variant="outlined"
                          color="primary"
                          onClick={navigateToForm}
                        >
                          {"Notify Selected Patient"}
                          <div style={{ marginLeft: 5, display: "flex" }}>
                            <ArrowRightAltIcon />
                          </div>
                        </Button>
                      )}
                    </div>

                    <div className={classes.patientDetailCard}>
                      {patientData?.data?.map((patient, key) => {
                        return (
                          <PatientDetailCard
                            {...patient}
                            data={patient}
                            id={key}
                            onselect={onPatientSelectedFromList}
                            selectedPatient={state.patient_id}
                            showSelectOptions={true}
                          />
                        );
                      })}
                    </div>
                  </Grid>
                </Grid>
              )}

            {(!state?.patient_name?.first_name ||
              patientData?.data?.length === 0) &&
              (!state?.ID || patientData?.data?.length === 0) &&
              (!state?.mobile?.number || patientData?.data?.length === 0) &&
              (!state?.email || patientData?.data?.length === 0) && (
                <div style={{ marginTop: "30%", padding: 16 }}>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    align="center"
                    gutterBottom
                  >
                    {"No Existing Patient Record Found"}
                  </Typography>
                  <Button
                    id="CreatePatientAndNotify"
                    variant="contained"
                    color="primary"
                    disabled={upsertPatientData?.loading}
                    onClick={createPatientAndNavigate}
                    fullWidth
                  >
                    {upsertPatientData?.loading
                      ? "Creating Patient Record..."
                      : "Create Patient & Notify"}
                    <div style={{ marginLeft: 5, display: "flex" }}>
                      <ArrowRightAltIcon />
                    </div>
                  </Button>
                </div>
              )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
