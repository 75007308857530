import React from "react";
import {
  Grid,
  makeStyles,
  Divider,
  Typography,
  IconButton,
} from "@material-ui/core";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import VisibilityIcon from "@material-ui/icons/Visibility";
import GetAppIcon from "@material-ui/icons/GetApp";
import PrintIcon from "@material-ui/icons/Print";
import AnnexForm from "./annexForm";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "pfb-binder";
import copy from "fast-copy";
const { VIEW_AND_PDF_ANNEX7 } = actions;
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    // padding: theme.spacing(2)
  },
  girdItem: {
    width: "100%",
  },
  header: {
    paddingTop: theme?.spacing(2),
    paddingLeft: theme?.spacing(2),
    paddingBottom: theme?.spacing(1),
    paddingRight: theme?.spacing(2),
    borderBottom: "1px solid #e0e0e0",
    // color: theme.palette.primary.main
  },
  heading: {
    fontSize: "24px",
  },
  pdfcontainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme?.spacing(2),
  },
  textcolor: {
    color: theme?.palette?.hash,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "800px",
    height: "700px",
    overflowY: "scroll",
    backgroundColor: theme?.palette?.background?.paper,
    boxShadow: theme?.shadows[5],
    padding: theme?.spacing(1.2, 4, 3),
    color: "#000",
    fontFamily: "Roboto",
    border: "4px solid rgba(0,0,0,0.4)",
  },
  headtxt: {
    fontSize: "16px",
  },
  headtxt2: {
    fontSize: "12px",
  },
  headtxt3: {
    fontSize: "16px",
    textTransform: "capitalize",
  },
  headtxt4: {
    fontSize: "14px",
    fontStyle: "italic",
  },
  divide: {
    margin: "10px 0px 10px 0px",
  },
  patienthead: {
    fontSize: "14px",
    fontWeight: "bold",
    color: theme?.palette?.primary?.main,
  },
  titl: {
    fontSize: "14px",
  },
  titl1: {
    fontSize: "14px",
    color: "#57576e",
    fontWeight: "bold",
  },
}));

export const ViewAnnex = (props) => {
  const classes = useStyles();
  const [showAnnex, setShowAnnex] = React.useState(false);
  const [isValue, setIsValue] = React.useState(0);
  const [view, setView] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const patientannex7data = useSelector(
    (state) => state?.annex7Slice?.getviewpdfannex7
  );
  //  console.log(patientannex7data.data);
  const handleOpen = () => {
    setOpen(true);
    getViewData();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDownloadClicked = () => {
    // debugger;
    if (isValue === 1) {
      setIsValue(0);
    } else {
      setIsValue(1);
    }
    if (showAnnex === false) {
      setShowAnnex(true);
    } else {
      setShowAnnex(false);
    }
  };
  const onPrint = () => {
    // debugger;
    if (isValue === 2) {
      setIsValue(0);
    } else {
      setIsValue(2);
    }
    if (showAnnex === false) {
      setShowAnnex(true);
    } else {
      setShowAnnex(false);
    }
  };

  const handleGetData = () => {};

  React.useEffect(() => {
    const getAnnexPatientData = async () => {
      let patient_id = props.data.patient_id;
      let form_id = props.data.form_id;
      Promise.resolve(dispatch(VIEW_AND_PDF_ANNEX7({ form_id, patient_id })));
    };
    getAnnexPatientData();
  }, [props.data.form_id, dispatch, props.data.patient_id]);

  const getViewData = async () => {
    let dat = await copy(patientannex7data);
    let list = [];
    list.push(patientannex7data.data[0]);
    const accompanyName = list[0]?.questions?.filter(
      (el) => el.question_name === "Accompany by(Mother/Father/Guardian)"
    );
    const identityCard = list[0]?.questions?.filter(
      (el) => el.question_name === "Identity Card Number"
    );
    const hospitalNumber = list[0]?.questions?.filter(
      (el) => el.question_name === "Hospital/Clinic Registration Number"
    );
    const ward = list[0]?.questions?.filter(
      (el) => el.question_name === "Ward"
    );
    const caseDetection = list[0]?.questions?.filter(
      (el) => el.question_name === "Case detection classification"
    );
    const statusOfPatient = list[0]?.questions?.filter(
      (el) => el.question_name === "Status of patient"
    );
    const dateOfOnset = list[0]?.questions?.filter(
      (el) => el.question_name === "Date of onset"
    );
    // const laboratoryInvestigation = list[0]?.questions?.filter(el => el.question_name === "Laboratory Investigation");
    const laboratoryInvestigationResult = list[0]?.questions?.filter(
      (el) => el.question_name === "Laboratory Investigation Result"
    );
    const diagnosisStatus = list[0]?.questions?.filter(
      (el) => el.question_name === "Diagnosis Status"
    );
    const nameOfMedicalPractitioner = list[0]?.questions?.filter(
      (el) => el.question_name === "Name of Medical Practitioner"
    );
    const nameAndAddressOfHospital = list[0]?.questions?.filter(
      (el) => el.question_name === "Name and Address of Hospital/clinic"
    );
    const dateOfNotification = list[0]?.questions?.filter(
      (el) => el.question_name === "Date of Notification"
    );

    const accompanyNameans = accompanyName[0].answers[0].answer.answer;
    const identityCardans = identityCard[0].answers[0].answer.answer;
    const hospitalNumberans = hospitalNumber[0].answers[0].answer.answer;
    const wardans = ward[0].answers[0].answer.answer;
    const statusOfPatientans = statusOfPatient[0].answers[0].answer.answer;
    const dateOfOnsetans = dateOfOnset[0].answers[0].answer.answer;
    // const laboratoryInvestigationans = laboratoryInvestigation[0].answers[0].answer.answer
    const laboratoryInvestigationResultans =
      laboratoryInvestigationResult[0].answers[0].answer.answer[0];
    const diagnosisStatusans = diagnosisStatus[0].answers[0].answer.answer[0];
    const nameOfMedicalPractitionerans =
      nameOfMedicalPractitioner[0].answers[0].answer.answer;
    const nameAndAddressOfHospitalans =
      nameAndAddressOfHospital[0].answers[0].answer.answer;
    const dateOfNotificationans =
      dateOfNotification[0].answers[0].answer.answer;
    const caseDetectionans = caseDetection[0].answers[0].answer.answer[0];

    setView({
      accompany: accompanyNameans,
      identityCard: identityCardans,
      hospitalNum: hospitalNumberans,
      ward: wardans,
      medicalPractitioner: nameOfMedicalPractitionerans,
      Case_Detection_Classfication: caseDetectionans,
      Patient_Status: statusOfPatientans,
      // Laboratory_investigation: laboratoryInvestigation[0].answers[0].answer.answer,
      Diagnosis_Status: diagnosisStatusans,
      dateOfNotify: dateOfNotificationans,
      dateOfOnset: dateOfOnsetans,
      laboratoryInvestigationResult: laboratoryInvestigationResultans,
      nameAndAddressOfHospital: nameAndAddressOfHospitalans,
    });
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} className={classes.header}>
          <Typography className={classes.heading}>{"View Annex7"}</Typography>
          <Typography variant="body2" color="primary">
            {`Annex7 Form of ${props.data.patient_name.first_name}`}
          </Typography>
        </Grid>
        <Divider />
        <Grid item xs={12} className={classes.pdfcontainer}>
          <PictureAsPdfIcon color="error" />
          <div
            style={{
              width: "90%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">
              {`${props.data.patient_name.first_name}`}
            </Typography>
            <div style={{ display: "flex" }}>
              <IconButton onClick={handleOpen}>
                <VisibilityIcon />
              </IconButton>
              <IconButton onClick={() => onDownloadClicked()}>
                <GetAppIcon />
              </IconButton>
              <IconButton onClick={() => onPrint()}>
                <PrintIcon />
              </IconButton>
            </div>
          </div>
        </Grid>
      </Grid>
      {showAnnex === true ? (
        <div style={{ display: "none" }}>
          <AnnexForm
            patientname={props.data.patient_name.first_name}
            formId={props.data.form_id}
            patientId={props.data.patient_id}
            onDownloadClicked={onDownloadClicked}
            onPrint={onPrint}
            isValue={isValue}
            patientannex7data={patientannex7data}
            handleClick={handleGetData}
          />
        </div>
      ) : (
        ""
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <IconButton
              style={{ position: "absolute", right: "392px" }}
              onClick={handleClose}
            >
              {" "}
              <HighlightOffIcon />
            </IconButton>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <img src="/images/UMMC_LOGO.png" alt="UMMC_logo" />
            </Grid>
            {/* <Grid container direction="column" justifyContent="center" alignItems="center">
                            <p className={classes.headtxt}>Form</p>
                            <p className={classes.headtxt}>(Regulation 2)</p>
                            <p className={classes.headtxt}> PREVENTION AND CONTROL OF INFECTIOUS DISEASE ACT 1988 </p>
                            <p className={classes.headtxt}>PREVENTION AND CONTROL OF INFECTIOUS DISEASE (NOTICE FORM) (AMENDMENT) REGULATIONS 2011</p>

                        </Grid>
                        <Grid container direction="column" justifyContent="center" alignItems="flex-end">
                            <p className={classes.headtxt2}>Notification Form: Rev/ 2010</p>
                            <p className={classes.headtxt2}>Serial No: 92093</p>
                        </Grid> */}
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <p className={classes.headtxt3}>
                NOTIFICATION OF COMMUNICABLE DISEASE TO BE REPORTED
              </p>
              <p className={classes.headtxt4}>
                ( Section 10,Prevention And Control of Communicable Disease Act,
                1988)
              </p>
            </Grid>
            <Divider style={{ margin: "10px 0px 10px 0px" }} />
            <p className={classes.patienthead}>A. PATIENT INFORMATION</p>
            <Grid container alignItems="center">
              <Grid item lg={5}>
                <p className={classes.titl}>1. Full Name</p>
                <p className={classes.titl}>
                  2. Accompany by (Mother/ Father/ Guardian)
                </p>
                {/* <p style={{ fontSize: "8px", fontStyle: "italic" }}>( If under age/ without Identity Card)</p> */}

                <p className={classes.titl}>3. Identity Card Number</p>
                <p className={classes.titl}>4. Hospital/ Clinc Reg.Number</p>
                <p className={classes.titl}>5. Ward</p>
                <p className={classes.titl}>7. Gender</p>
                <p className={classes.titl}>8. Date of Birth </p>
                <p className={classes.titl}>9. Age</p>
                <p className={classes.titl}>10. Telephone No. </p>
                <p className={classes.titl}>11. Current Address </p>
              </Grid>
              <Grid item lg={7}>
                <p className={classes.titl1}>
                  :{" "}
                  {`${props?.data?.patient_name?.first_name} ${
                    props?.data?.patient_name?.middle_name ?? ""
                  } ${props?.data?.patient_name?.last_name ?? ""}`}
                </p>
                <p className={classes.titl1}>: {view?.accompany ?? ""}</p>
                <p className={classes.titl1}>: {view?.identityCard ?? ""}</p>
                <p className={classes.titl1}>: {view?.hospitalNum ?? ""}</p>
                <p className={classes.titl1}>: {view?.ward ?? ""}</p>
                <p className={classes.titl1}>: {props?.data?.gender ?? ""}</p>

                <p className={classes.titl1}>
                  : {props?.data?.dob_age?.dob ?? ""}
                </p>
                <p className={classes.titl1}>
                  : {props?.data?.dob_age?.age ?? ""}
                </p>
                <p className={classes.titl1}>
                  :{" "}
                  {`${props?.data?.mobile?.phoneCode ?? ""} ${
                    props?.data?.mobile?.number ?? ""
                  }`}
                </p>
                <p className={classes.titl1}>
                  : {props?.data?.addresline ?? ""}
                </p>
              </Grid>
              {/* <Grid container justify="flex-start" alignItems="center">
                                <Grid lg={5} style={{ display: "flex" }}>
                                    <p className={classes.titl}>7. Gender :</p>
                                    <p className={classes.titl1} >{props?.data?.gender ?? ""}</p>
                                </Grid >
                                <Grid lg={4} style={{ display: "flex" }}>
                                    <p className={classes.titl}>8. Date of Birth :</p>
                                    <p className={classes.titl1}>{props?.data?.dob_age?.dob ?? ""}</p>
                                </Grid>
                                <Grid lg={3} style={{ display: "flex", justifyContent: "center" }}>
                                    <p className={classes.titl}>9. Age :</p>
                                    <p className={classes.titl1}>{
                                        props?.data?.dob_age?.age ?? ""
                                    }</p>
                                </Grid>

                            </Grid>
                            <Grid container justify="flex-start" alignItems="center" style={{ paddingTop: "5 px" }}>
                                <Grid lg={5} style={{ display: "flex" }}>
                                    <p className={classes.titl}>10. Telephone No. :</p>
                                    <p className={classes.titl1} >
                                        {`${props?.data?.mobile?.phoneCode ?? ""} ${props?.data?.mobile?.number ?? ""}`}

                                    </p>
                                </Grid>
                                <Grid lg={7} style={{ display: "flex" }}>
                                    <p className={classes.titl}>11. Current Address :</p>
                                    <p className={classes.titl1}> {props?.data?.addresline ?? ""}</p>
                                </Grid>

                            </Grid> */}
            </Grid>
            <Divider style={{ margin: "10px 0px 10px 0px" }} />
            <p className={classes.patienthead}>B. DISEASE DIAGNOSIS</p>
            <Grid container alignItems="center">
              <Grid item lg={5}>
                <p className={classes.titl}>
                  12. Case detection classification
                </p>
                <p className={classes.titl}>13. Status of Patient</p>
                <p className={classes.titl}>14. Date of Onset</p>
                <p className={classes.titl}>15. Laboratory Investigation</p>
                <p className={classes.titl}>
                  16. Laboratory investigation result
                </p>
                <p className={classes.titl}>17. Diagnosis Status</p>
              </Grid>
              <Grid item lg={7}>
                <p className={classes.titl1}>
                  : {view?.Case_Detection_Classfication ?? ""}
                </p>
                <p className={classes.titl1}>: {view?.Patient_Status ?? ""}</p>
                <p className={classes.titl1}>: {view?.dateOfOnset ?? ""}</p>
                <p className={classes.titl1}>
                  : {view?.laboratoryInvestigationResult ?? ""}
                </p>
                <p className={classes.titl1}>
                  : {view?.laboratoryInvestigationResult ?? ""}
                </p>
                <p className={classes.titl1}>
                  : {view?.Diagnosis_Status ?? ""}
                </p>
              </Grid>
            </Grid>
            <Divider style={{ margin: "10px 0px 10px 0px" }} />
            <p className={classes.patienthead}>C. NOTIFIER</p>
            <Grid container alignItems="center">
              <Grid item lg={5}>
                <p className={classes.titl}>18. Name of Medical Practitioner</p>
                <p className={classes.titl}>
                  16. Name and address of Hospital/ Clinic
                </p>
                <p className={classes.titl}>17. Date of Notification</p>
              </Grid>
              <Grid item lg={7}>
                <p className={classes.titl1}>
                  : {view?.medicalPractitioner ?? ""}
                </p>
                <p className={classes.titl1}>
                  : {view?.nameAndAddressOfHospital ?? ""}{" "}
                </p>
                <p className={classes.titl1}>: {view?.dateOfNotify ?? ""}</p>
              </Grid>
            </Grid>
            {/* <Grid container direction="column" justifyContent="center" alignItems="flex-end">
                            <p style={{ fontSize: "10px", fontStyle: "italic", paddingTop: "60px" }}>Signature of<br /> Medical Practitioner</p>
                        </Grid> */}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
