/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc Exporting all the screens from /src/screens
 */

export { default as NotFound } from "./notFound";
export { default as Login } from "./loginScreen/Login";
export { default as AccessManagement } from "./accessManagement/AccessManagement";
export { default as Generalmaster } from "./generalMaster/generalMaster";
export { default as Reportviewer } from "./reportViewer/ReportViewer";
// export { default as Formrenderer } from "./formRenderer/componentToRender";
export { default as Dashboard } from "./dashboard/dashboard";
export { default as ManageAssessment } from "./manageAssessment/manageAssessment";
export { default as ManageLevel } from "./manageLevel/index";
export { default as TaskAndDocument } from "./taskAndDocument";
export { default as TaskViewers } from "./taskViewer";
export { default as Client } from "./client";
export { default as AddEditClientParent } from "./addEditClient";
export { default as ManageFacility } from "./manageFacility/index";
export { default as CreateFacility } from "./createFacility";
export { default as ManageForms } from "./ManageForms/index";
export { default as FormConfigurator } from "./formConfigure";
export { default as Notify } from "./notify";
export { default as NotificationDetail } from "./notificationDetails";
export { default as FormRenderer } from "./formRenderer";
export { default as ViewOrganization } from "./ViewOrganization";
export { default as ViewLevel } from "./ViewLevel";
export { default as NotificationsV2 } from "./listNotificationsV2";
export * from "./reportViewer";
