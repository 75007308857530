import { Autocomplete } from "@material-ui/lab";
import { TextField, makeStyles, Grid, Typography } from "@material-ui/core";
import React from "react";
import { Debounce } from "../../utils";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
const typePeriod = ["One Time", "Quater", "Multiple"];
const countPeriod = {
  "One Time": 1,
  Quater: 4,
};
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

function AssessmentFormMappingDialog(props) {
  // const classes = useStyles();
  // const [typeOfPeriod, setTypeOfPeriod] = React.useState("");
  const [numberOfPeriod, setNumberOfPeriod] = React.useState(
    props?.mappingstate?.periodType?.length
  );

  console.log(props);
  const [listData, setListData] = React.useState(
    props.mappingstate?.periodType?.length > 0
      ? props.mappingstate?.periodType
      : []
  );
  const generateList = (val) => {
    setNumberOfPeriod(val);
    let totalNumberOfIteration = countPeriod[val] ?? val;
    let constructData = [];
    for (let i = 0; i < totalNumberOfIteration; i++) {
      constructData.push({ from: null, to: null });
    }
    setListData(constructData);
  };
  const handleDateTime = (evt, index, name) => {
    let data = [...JSON.parse(JSON.stringify(listData))];
    console.log(
      new Date(evt).toLocaleDateString().split("/").reverse().join("-")
    );
    // let copyData = [...listData];
    data[index][name] = evt;
    // const getMonth = new Date(evt).getMonth() + 1;
    // let arrayOfDate = evt.toString().split(" ");
    // copyData[index][name] = `${getMonth}-${arrayOfDate[2]} ${arrayOfDate[4]}`;
    setListData(data);
    props.mappedData(
      {
        ...props.mappingstate,
        periodType: data,
      },
      props?._key,
      props.type
    );
  };

  const timeDelay = Debounce(generateList, 100);
  return (
    <>
      <div className="AssessmentDialog">
        <Typography
          variant="h4"
          style={{ textAlign: "center", color: "black" }}
        >
          Period
        </Typography>
        <Grid container>
          {/*  <Grid item style={{ flexGrow: "1" }}>
            <Autocomplete
              size="small"
              fullWidth
              options={typePeriod ?? []}
              getOptionLabel={(options) => options}
              onChange={(evt, value) => (
                setTypeOfPeriod(value), generateList(value)
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Type Of Period"
                  variant="outlined"
                />
              )}
            />
          </Grid> */}

          <Grid item md={12} lg={12} sm={12}>
            <TextField
              value={numberOfPeriod}
              fullWidth
              size="small"
              variant="outlined"
              label="Number Of Period"
              type="number"
              onChange={(e) => timeDelay(e.target.value)}
              InputProps={{ inputProps: { min: 0, max: 10 } }}
            />
          </Grid>
        </Grid>

        <Grid container style={{ gap: "1em", flexGrow: "1" }}>
          {listData.map((e, index) => (
            <React.Fragment key={e + index}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item style={{ flexGrow: "1" }}>
                  {/* <TextField
                  name="from"
                  variant="outlined"
                  value={e.from}
                  onChange={(e) => handleDateTime(e, index)}
                  fullWidth
                  id="datetime-local"
                  label="From"
                  type="datetime-local"
                  defaultValue="2017-05-24T10:30"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                /> */}
                  <DateTimePicker
                    autoOk
                    size="small"
                    fullWidth
                    label="From"
                    variant="inline"
                    inputVariant="outlined"
                    value={e.from ?? null}
                    onChange={(val) => handleDateTime(val, index, "from")}
                  />
                </Grid>
                <Grid item style={{ flexGrow: "1" }}>
                  {/* <TextField
                  variant="outlined"
                  name="to"
                  value={e.to}
                  onChange={(e) => handleDateTime(e, index)}
                  fullWidth
                  id="datetime-local"
                  label="To"
                  type="datetime-local"
                  defaultValue="2017-05-24T10:30"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                /> */}

                  <DateTimePicker
                    autoOk
                    minDate={new Date(e.from).toISOString().split("T")[0]}
                    size="small"
                    fullWidth
                    label="To"
                    variant="inline"
                    inputVariant="outlined"
                    value={e.to ?? null}
                    onChange={(val) => handleDateTime(val, index, "to")}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </React.Fragment>
          ))}
        </Grid>
      </div>
    </>
  );
}

export default AssessmentFormMappingDialog;
