import React from "react";
import {
  Button,
  makeStyles,
  Divider,
  Typography,
  Grid,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { AlertContext, BackdropContext } from "../../contexts";
import Select from "../select";
// store
import { actions } from "pfb-binder";
import { useSelector, useDispatch } from "react-redux";
import { AlertProps, Message } from "../../utils";

// Store Action's list
const { AddAssessment } = actions;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  header: {
    padding: "10px 20px",
    color: theme.palette.primary.main,
  },
  countryParent: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  countryCodeParent: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  btnParent: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  btn: {
    alignItems: "center",
    // justifyContent:'center'
  },
}));

export const AddEditAssessment = (props) => {
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);

  const classes = useStyles();

  const [state, setState] = React.useState({
    name: props?.editData?.assessment_name ?? "",
    // id: props?.editData?.disease_id ?? "",
    errorValidation: {
      error: {
        name: false,
      },
    },
  });

  // Declaring Store dispatch
  const dispatch = useDispatch();

  const upsertDisease = false;
  useSelector((state) => state?.diseaseSlice?.upsertDisease);

  const onChangeInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const validation = () => {
    let isValidname = state?.name?.trim().length === 0 ? false : true;

    if (isValidname) {
      return true;
    } else {
      state.errorValidation.error = {
        name: state?.name?.trim().length === 0 ? true : false,
      };
      setState({ ...state });
    }
  };

  const submitForm = () => {
    const isValidated = validation();

    if (!isValidated) {
      return false;
    }
    let __params = {
      assessment_name: state?.name,
    };
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: props.isEdit ? Message.disease_update : Message.disease_add,
    });
    let apidata = {
      dbname: process.env.REACT_APP_DB,
      entity: "master_assessment",
      ismeta: true,
      metadataid: process.env.REACT_APP_METADATAID,
      metadatadbname: process.env.REACT_APP_METADATA_DB_NAME,
      filter: props.isEdit ? { _id: `${props?.editData?._id}` } : undefined,
      doc: __params,
    };

    Promise.resolve(dispatch(AddAssessment(apidata))).then((res) => {
      backdrop.setBackDrop({ ...backdrop, open: false });
      props.onActionCompleted(props.isEdit);
      let resStatus = res.payload.error;
      let statusMessage = props.isEdit
        ? "Assessment Updated Successfully !"
        : "Assessment Added Successfully !";
      let statusMessage2 = props.isEdit
        ? "Assessment Updated Failed!"
        : "Assessment Adding Failed !";
      if (res?.payload?.message === "Assessment Already Exist !") {
        alert.setSnack({
          ...alert,
          open: true,
          severity: resStatus
            ? AlertProps.severity.error
            : AlertProps.severity.success,
          msg: res?.payload?.message,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: resStatus
            ? AlertProps.severity.error
            : AlertProps.severity.success,
          msg: resStatus ? statusMessage2 : statusMessage,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
    });
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        <Grid item>
          <Typography className={classes.header}>
            {props?.isEdit ? "Update Assessment" : "Add Assessment"}
          </Typography>
          <Divider />
        </Grid>

        <Grid item className={classes.countryParent}>
          <TextField
            onChange={(e) => onChangeInput(e)}
            name="name"
            value={state?.name ?? ""}
            fullWidth
            label="Assessment Name"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            error={state.errorValidation.error.name}
            helperText={
              state.errorValidation.error.name === true
                ? Message.requiredMessage
                : ""
            }
          />
        </Grid>

        <Grid item className={classes.btnParent}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            disabled={upsertDisease?.loading}
            onClick={() => submitForm()}
            id="addAndUpdateDiseaseButton"
          >
            {props?.isEdit
              ? upsertDisease?.loading
                ? "Updating..."
                : "Update Assessment"
              : upsertDisease?.loading
              ? "Adding..."
              : "Add Assessment"}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
