import React from "react";
import { Grid, Paper } from "@material-ui/core";
import Chart from "./charts";

const ChartLayout = () => {
  return (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} xl={12} xs={12}>
        <Chart />
      </Grid>
    </Grid>
  );
};

export default ChartLayout;
