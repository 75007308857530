import { Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import copy from "fast-copy";
import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { actions } from "pfb-binder";
import { MuiSelect } from "../../../components/muiSelect/MuiSelect";
import { AlertContext, DialogContext } from "../../../contexts";
import { AlertProps, Message } from "../../../utils";
import { AssessmentFormMappingDialog } from "../../../components";
import MappingTable from "./mappingTable";
import { NetworkCall } from "../../../networkcall";
// import { async } from "taskviewersurveillance";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    backgroundColor: "#F8F8F8",
  },
  // mapform: {
  //   color: theme.palette.primary.main,
  //   backgroundColor: theme.palette.primary.light,
  // },
  main: {
    backgroundColor: "#FFFFFF",
    marginTop: "20px",
  },
  main2: {
    backgroundColor: "#FFFFFF",
  },
  textcolor: {
    // color: theme.palette.hash.primary,
    marginBottom: "6px",
  },
}));

export const ClientMapping = (props) => {
  const classes = useStyles();
  const { clientId, upsertDiseaseMappedWithClient, tenantid } = props;
  const dispatch = useDispatch();
  const alertContext = React.useContext(AlertContext);
  const dialogContext = React.useContext(DialogContext);
  const [mappingEditData, setEditData] = React.useState({});
  const [mappingstate, setState] = React.useState({
    assessment: {},
    form: {},
    selectedLevel: {
      levelstructure: "",
      leveldata: "",
    },
    selectedOrg: {},
    clonedFormFrom: {},
    // error: {},
  });
  const mappedAssessment = useSelector(
    (state) => state?.manageClientSlice?.getMappedDiseaseWithClient?.data
  );

  const mappedAssessmentData = useSelector((state) => {
    return state?.manageClientSlice?.getAllDiseasesClient?.data;
  });

  const diseaseByForm = useSelector(
    (state) => state?.manageClientSlice.getFormByDisease?.data
  );

  const getParentMapping = useSelector(
    (state) => state?.codingMaster?.getMappingDiseaseCodingMaster?.data
  );

  // const [mappedFormData, setFormList] = React.useState([]);
  // const [diseaseMapped, setDiseaseMapped] = React.useState([]);

  const orgData = useSelector(
    (state) => state?.manageClientSlice?.getOrgMappedWithClient?.data
  );
  // console.log("mappingstate", mappingstate);

  const changeState = (key, value, startLevel = 0) => {
    console.log(key, value);
    let updatedState = mappingstate;
    if (key === "selectedOrg") {
      updatedState[key] =
        Object.values(value).length > 0
          ? { name: value?.name ?? "", _id: value?._id ?? "" }
          : {};
      updatedState["selectedLevel"] = { level: value?.level };

      // if (key === "disease" && value) {
      //   getFormByDisease(value?._id);
      // }
      onStateChangeCallback(key, value, updatedState, startLevel);
    } else {
      updatedState[key] = value;
      if (key === "assessment" && value) {
        getFormByDisease(value?._id);
      }
      onStateChangeCallback(key, value, updatedState, startLevel);
    }
  };

  const onStateChangeCallback = (key, value, updatedState, startLevel = 0) => {
    if (key === "assessment") {
      updatedState.clonedFormFrom = {};
    }
    setState({ ...mappingstate, ...updatedState });
  };

  React.useEffect(() => {
    if (clientId) {
      getAllMappingData();
    }
    // eslint-disable-next-line
  }, [clientId]);

  const getAllMappingData = async () => {
    await dispatch(actions.GET_MAPPED_DISEASE_WITH_CLIENT({ id: clientId }));
    let vms = {
      dbname: process.env.REACT_APP_DB,
      entity: process.env.REACT_APP_ASSESSMENT_ENTITY,
      sortkey: "disease_name",
    };

    await dispatch(actions.GET_ALL_DISEASES_FOR_CLIENT(vms));
    await dispatch(actions.GET_ORG_MAPPED_WITH_CLIENT({ id: clientId }));
    await dispatch(
      actions.GET_MAPPING_DISEASE_CODINGMASTER({
        state: process.env.REACT_APP_IDM_MANAGEDISEASE,
      })
    );
    // await dispatch(actions.GET_PARENT)
  };

  const getFormByDisease = async (id) => {
    await dispatch(actions.GET_FORM_BY_DISEASE({ id: id }));
  };

  const onDeleteIconClicked = (row, mappingDetail) => {
    dialogContext.setDialog({
      ...dialogContext,
      open: true,
      title: "Are you Sure ?",
      body: Message.data_security_delete_message(mappingDetail),
      positiveBtn: "Confrim",
      negativeBtn: "Cancel",
      onOk: () => deleteMappingTableRow(row),
    });
  };
  const apiCall = async (val, key) => {
    NetworkCall(
      process.env.REACT_APP_ARANGO_URL_UPSERT,
      "POST",
      JSON.stringify([
        {
          db_name: process.env.REACT_APP_DB,
          entity: process.env.REACT_APP_AssessmentFormMap,
          ...(key?._key && { filter: { _key: key._key } }),
          doc: { mappingTable: { ...val } },
        },
      ]),
      {
        "Content-Type": "application/json",
        Accept: "application/json",
      }
    )
      .then(() =>
        alertContext.setSnack({
          ...alertContext,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Updated Mapped Assessment Successfully!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        })
      )
      .catch((err) => console.error(err))
      .finally(
        async () =>
          await dispatch(
            actions.GET_MAPPED_DISEASE_WITH_CLIENT({ id: clientId })
          )
      );
    dialogContext.setDialog({
      ...dialogContext,
      open: false,
    });
  };
  const handleEditMapping = (val, entireData, type) => {
    console.log(val, entireData, type);
    dialogContext.setDialog({
      ...dialogContext,
      open: true,
      title: "",
      body: (
        <AssessmentFormMappingDialog
          mappedData={handleEditMapping}
          mappingstate={val}
          _key={entireData}
          type={type}
        />
      ),
      positiveBtn: "Apply",
      negativeBtn: "Cancel",
      onOk: () =>
        type === "noedit" ? addToMappedRow(val) : apiCall(val, entireData),
    });
    return val;
  };
  const deleteMappingTableRow = async (row) => {
    dialogContext.setDialog({ ...dialogContext, open: false });
    // let mappingMasterName = getParentMapping[0]?.coding?.display;
    let mappingMasterId = getParentMapping[0]?.coding?._id;

    let res = await dispatch(
      actions.SOFT_DELETE_DISEASEMAPPED({
        _key: row?._key,
        parentUniqueId: mappingMasterId,
      })
    );
    if (!res.payload.error) {
      alertContext.setSnack({
        ...alertContext,
        open: true,
        severity: AlertProps.severity.success,
        msg: "Mapped Assessment & form deleted successfully",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
      await dispatch(actions.GET_MAPPED_DISEASE_WITH_CLIENT({ id: clientId }));
    } else {
      alertContext.setSnack({
        ...alertContext,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Unable to delete client !",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };

  const addToMappedRow = async (mappedState) => {
    debugger;
    let mappingMasterName = getParentMapping[0]?.coding?.display;
    let mappingMasterId = getParentMapping[0]?.coding?._id;
    await upsertDiseaseMappedWithClient(
      mappedState,
      mappingMasterName,
      mappingMasterId,
      tenantid
    );
    dialogContext.setDialog({
      ...dialogContext,
      open: false,
    });
    setState({
      assessment: {},
      form: {},
      selectedLevel: {
        levelstructure: "",
        leveldata: "",
      },
      selectedOrg: {},
      clonedFormFrom: {},
    });
  };
  const checkOptionSelected = (mappingstate) => {
    console.log(mappingstate);
    let hasdisease =
      Object.values(mappingstate?.assessment).length > 0 ? true : false;
    let hasfrom =
      Object.values(mappingstate?.clonedFormFrom).length > 0 ? true : false;
    let hasorg =
      Object.values(mappingstate?.selectedOrg).length > 0 ? true : false;
    if (hasdisease && hasfrom && hasorg) {
      return false;
    } else {
      return true;
    }
  };
  console.log(diseaseByForm, orgData);
  return (
    <Paper style={{ padding: "8px" }} elevation={0}>
      <Grid container direction="row" alignItems="center" spacing={2}>
        {/* <Grid item xs={12}>
        <div style={{ display: "flex" }}>
          <Typography variant="body1">{""}</Typography>

        </div>
      </Grid> */}

        <Grid item xs={12} sm={12} md={3}>
          <Typography variant="body1" color={"textSecondary"}>
            Mapped Assessment
          </Typography>
          <MuiSelect
            id="selectClientType"
            className="basic-single"
            classNamePrefix="select"
            value={mappingstate?.assessment}
            placeholder="Select"
            getOptionLabel={(option) => option?.name}
            handleselect={(data) => changeState("assessment", data ?? {})}
            // label={"coding"}
            option={mappedAssessmentData ?? []}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <Typography variant="body1" color={"textSecondary"}>
            Mapped Forms
          </Typography>
          <MuiSelect
            id="selectClientType"
            className="basic-single"
            classNamePrefix="select"
            value={mappingstate?.clonedFormFrom}
            placeholder="Select"
            getOptionLabel={(option) => option?.name}
            handleselect={(data) => changeState("clonedFormFrom", data ?? {})}
            option={diseaseByForm ?? []}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <Typography variant="body1" color={"textSecondary"}>
            Organization
          </Typography>
          <MuiSelect
            id="selectClientType"
            className="basic-single"
            classNamePrefix="select"
            value={mappingstate?.selectedOrg}
            placeholder="Select"
            groupBy={(option) => option?.orgType}
            getOptionLabel={(option) => option?.name}
            handleselect={(data) => changeState("selectedOrg", data ?? [])}
            // label={"coding"}
            // option={orgData ?? []}
            option={
              orgData.length !== 0 && Array.isArray(orgData)
                ? copy(orgData).sort(
                    (a, b) => -b.orgType.localeCompare(a.orgType) ?? []
                  )
                : []
            }
            disabled={!mappingstate?.assessment}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          style={{ textAlign: "end", marginTop: 28 }}
        >
          <Button
            id="mapFormAndAssessment"
            variant="contained"
            // className={classes.mapform}
            color={"primary"}
            onClick={() =>
              // addToMappedRow()
              handleEditMapping(mappingstate, undefined, "noedit")
            }
            disabled={checkOptionSelected(mappingstate)}
          >
            {"Map Form & Assessment"}
          </Button>
        </Grid>

        {/* <Grid item xs={12} style={{ marginTop: 16, marginBottom: 16 }}>
        {mappingstate.error.mappingTable && (
          <Typography variant="caption" color="error">
            {mappingstate.state.error.mappingTable}
          </Typography>
        )}
        <MappingTable
          rows={row?.mappingTable}
          deleteMappingTableRow={onDeleteIconClicked}
        />
      </Grid> */}
      </Grid>
      <Grid item xs={12} style={{ marginTop: 16, marginBottom: 16 }}>
        {/* {mappingstate?.error?.mappingTable && (
          <Typography variant="caption" color="error">
            {mappingstate?.state?.error?.mappingTable}
          </Typography>
        )} */}
        <MappingTable
          rows={mappedAssessment}
          deleteMappingTableRow={onDeleteIconClicked}
          editMapping={handleEditMapping}
        />
      </Grid>
    </Paper>
  );
};
