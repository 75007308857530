import React from "react";
import { makeStyles } from "@material-ui/core";
import { SensibleDrawer, TopNavBar } from "../components";
import { checkWithIdm } from "atp-casbin-js";
import { NavBarArr } from "../utils";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { AuthContext } from "../contexts";
import { AlertContext } from "../contexts";
// import Navbar from "../components/navbar";
import { AxiosCall } from "../utils";
import { AlertProps } from '../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  content: {
    width: "100%",
    height: "calc(100% - 64px)",
    overflow: "auto",
  },
}));

const withNavBars = (Component) => (props) => {
  let history = useHistory();
  const classes = useStyles();

  const auth = React.useContext(AuthContext);
  const [userInfo, setUserInfo] = useState("");
  const [navlist, setNavlist] = useState([]);
  // const [showButtonExpand, setButtonExpand] = React.useState(false);
  // const [open, setOpen] = React.useState(false);
  const alert = React.useContext(AlertContext);
  React.useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      PaymentBased();
      GetUserName();
      GetRepoList();
      GetRoleBased();
      
    } else {
      history.push("/");
      localStorage.clear();
    }
    PaymentBased();
    GetUserName();
    GetRepoList();
    GetRoleBased();
    //eslint-disable-next-line
  }, []);

  const GetUserName = () => {
    const token = localStorage.getItem("access_token");
    const parsedToken = token?.split(".")[1];
    const userInfos = JSON.parse(window.atob(parsedToken));
    setUserInfo(userInfos);
    
  };

  const PaymentBased = async () => {
    let RoleId = localStorage.getItem("RoleId");
    let params3 = {
      db_name: process.env.REACT_APP_DB,
      entity: "IDM_PermissionRoleMapping",
      filter: `IDM_PermissionRoleMapping.roleid=='${RoleId}' && IDM_PermissionRoleMapping.activestatus==true`,
      return_fields: "IDM_PermissionRoleMapping",
    };
    let resvalue = await AxiosCall(
      "post",
      `${process.env.REACT_APP_BASE_URL}/api/read_documents`,
      params3
    );
    let RoleName = resvalue?.result[0]?.rolename

    if(RoleName?.toString()?.toLowerCase()?.includes("super"))
    {
      console.log("superadmin")
    }

    else{
    const person =  localStorage.getItem("queue_token");
    const parsedToken = person?.split(".")[1];
    const userInfos = JSON.parse(window.atob(parsedToken));

    const personId = userInfos?.personid

    let params = {
      db_name: process.env.REACT_APP_DB,
      entity: "Person",
      filter: `Person._id=='${personId}' && Person.activestatus==true`,
      return_fields: "Person.orgType",
    };
    let res = await AxiosCall(
      "post",
      `${process.env.REACT_APP_BASE_URL}/api/read_documents`,
      params
    );
    let OrgId = res?.result[0];

    let params1 = {
      db_name: process.env.REACT_APP_DB,
      entity: "Organization",
      filter: `Organization._id=='${OrgId}' && Organization.activestatus==true`,
      return_fields: "Organization",
    }

    let res1 = await AxiosCall(
      "post",
      `${process.env.REACT_APP_BASE_URL}/api/read_documents`,
      params1
    );

    if(res1?.result?.length > 0)
    {
      let Orgvalue = res1?.result[0]
      const PaymentRequired = Orgvalue?.isPaymentRequired
      const paymentStatus = Orgvalue?.paymentStatus

      if(PaymentRequired)
      {
        if(paymentStatus == "pending")
        {
          history.push("/");
          localStorage.clear();
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.warning,
            msg: `Payment Status is Pending`,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center
        })
        }
      }
    }
  }

  }

  const GetRepoList = async () => {
    let roleid = localStorage.getItem("RoleId");
    let roledata = atob(localStorage.getItem("permissionData"));
    let _roledata = JSON.parse(roledata);
    let repository = _roledata.find(
      (l) => l?.permissionRepo?.route_url === window.location.pathname
    );

    // setSelectedItem(repository);

    let hasAccessCheck = await checkWithIdm(
      _roledata,
      roleid,
      repository?.permissionRepo?.rep_name,
      "read"
    );

    if (hasAccessCheck) {
      let NlistCheckRole = NavBarArr.map(async (l) =>
        (await checkWithIdm(_roledata, roleid, l?.repo, "read"))
          ? { ...l, read: true }
          : { ...l, read: false }
      );
      Promise.all(NlistCheckRole).then((values) => {
        const Navbarlist = values.filter((li) => li.read === true);
        setNavlist(Navbarlist);
      });
    } else {
      history.push("/");
      localStorage.clear();
    }
  };

  const GetRoleBased = async () => {

    if(auth?.user?.length == 0)
    {
      const Arr = [];
    let RoleId = localStorage.getItem("RoleId");
    let params = {
      db_name: process.env.REACT_APP_DB,
      entity: "IDM_PermissionRoleMapping",
      filter: `IDM_PermissionRoleMapping.roleid=='${RoleId}' && IDM_PermissionRoleMapping.activestatus==true`,
      return_fields: "IDM_PermissionRoleMapping",
    };
    let res = await AxiosCall(
      "post",
      `${process.env.REACT_APP_BASE_URL}/api/read_documents`,
      params
    );
    let RoleName = res?.result[0]?.rolename
    Arr.push(RoleName)
   if(RoleName?.toString()?.toLowerCase()?.includes("notifi"))
   {
    history.push("/notify")
    
   }

   else if(RoleName?.toString()?.toLowerCase()?.includes("verif"))
   {
    history.push("/taskviewer")
   
   }

   else{
    history.push("/Home")

   }
   auth?.setAuth(Arr);
  }

  }

  return (
   
    <div className={classes.root}>
     
      <div>
        <TopNavBar user={userInfo?.name} />
      </div>
      <div className={classes.content}>
        <SensibleDrawer
          sidePanellist={navlist}
          Component={Component}
          // setButtonExpand={setButtonExpand}
          // showButtonExpand={showButtonExpand}
          // open={open}
          // setOpen={setOpen}
          {...props}
        />
      </div>
    </div>
     
  );
};

export default withNavBars;
