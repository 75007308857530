import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import Select from "react-select";
// store
import { useDispatch, useSelector } from "react-redux";
import { actions } from "pfb-binder";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  levelName: {
    marginBottom: 5,
  },
  errorText: {
    color: "red",
    fontSize: "0.75rem",
  },
  hash: {
    color: theme?.palette?.hash?.primary,
  },
}));

// export const countryLevelData = {
//     loading: false,
//     error: false,
//     data: {
//         "level1": {
//             "_id": "LevelStructure/10002",
//             "_key": "10002",
//             "_rev": "_exTlyzK---",
//             "activestatus": true,
//             "child": [
//                 {
//                     "_id": "LevelData/10001",
//                     "_key": "10001",
//                     "_rev": "_exTue6O--K",
//                     "activestatus": true,
//                     "child_level_id": "6a81d6bf-fff5-4f5f-916c-c4b41d8f2f9f",
//                     "child_level_no": 1,
//                     "child_name": "India",
//                     "client_id": "client/10004",
//                     "country_id": "e68df9ff-a8c7-40e9-91c9-a834891d429d",
//                     "createdby": "",
//                     "createddate": 1662801329,
//                     "is_active": true,
//                     "level": 1,
//                     "level_id": "49035313-5968-4377-b497-702a01616bbc",
//                     "parent_child_level_id": "",
//                     "updatedate": 1663048909,
//                     "updatedby": "",
//                     "value": "6a81d6bf-fff5-4f5f-916c-c4b41d8f2f9f",
//                     "label": "India"
//                 },
//                 {
//                     "_id": "LevelData/10002",
//                     "_key": "10002",
//                     "_rev": "_exTue6O--Q",
//                     "activestatus": true,
//                     "child_level_id": "c91ace95-8d6d-4d5d-8363-c5b8f0119f25",
//                     "child_level_no": 2,
//                     "child_name": "USA",
//                     "client_id": "client/10004",
//                     "country_id": "e68df9ff-a8c7-40e9-91c9-a834891d429d",
//                     "createdby": "",
//                     "createddate": 1662801386,
//                     "is_active": true,
//                     "level": 1,
//                     "level_id": "49035313-5968-4377-b497-702a01616bbc",
//                     "parent_child_level_id": "",
//                     "updatedate": 1663048909,
//                     "updatedby": "",
//                     "value": "c91ace95-8d6d-4d5d-8363-c5b8f0119f25",
//                     "label": "USA"
//                 }
//             ],
//             "client_id": "client/10004",
//             "country_id": "e68df9ff-a8c7-40e9-91c9-a834891d429d",
//             "createdby": "",
//             "createddate": 1662803628,
//             "is_active": true,
//             "level": 1,
//             "level_id": "49035313-5968-4377-b497-702a01616bbc",
//             "level_name": "Country",
//             "updatedate": 1662803628,
//             "updatedby": "",
//             "value": "49035313-5968-4377-b497-702a01616bbc",
//             "label": "Country"
//         },
//         "level2": {
//             "_id": "LevelStructure/10003",
//             "_key": "10003",
//             "_rev": "_exUIHbi---",
//             "activestatus": true,
//             "child": [
//                 {
//                     "_id": "LevelData/10003",
//                     "_key": "10003",
//                     "_rev": "_exTue6O--I",
//                     "activestatus": true,
//                     "child_level_id": "6a81d6bf-fff5-4f5f-916c-c4b41d8f2f90",
//                     "child_level_no": 1,
//                     "child_name": "Chennai",
//                     "client_id": "client/10004",
//                     "country_id": "e68df9ff-a8c7-40e9-91c9-a834891d429d",
//                     "createdby": "",
//                     "createddate": 1662801459,
//                     "is_active": true,
//                     "level": 2,
//                     "level_id": "f619c5f8-d443-41a2-b190-bebaf151313e",
//                     "parent_child_level_id": "6a81d6bf-fff5-4f5f-916c-c4b41d8f2f9f",
//                     "updatedate": 1663048909,
//                     "updatedby": "",
//                     "value": "6a81d6bf-fff5-4f5f-916c-c4b41d8f2f90",
//                     "label": "Chennai"
//                 },
//                 {
//                     "_id": "LevelData/10012",
//                     "_key": "10012",
//                     "_rev": "_exTue6O--O",
//                     "activestatus": true,
//                     "child_level_id": "50a9bfee-e80d-432b-969e-3b876c133004",
//                     "child_level_no": 2,
//                     "child_name": "tnj",
//                     "client_id": "client/10004",
//                     "country_id": "e68df9ff-a8c7-40e9-91c9-a834891d429d",
//                     "created_at": "2022-09-12T10:17:57.384Z",
//                     "created_by": "user profile id not available",
//                     "createdby": "",
//                     "createddate": 1662978857,
//                     "is_active": true,
//                     "level": 2,
//                     "level_id": "f619c5f8-d443-41a2-b190-bebaf151313e",
//                     "parent_child_level_id": "6a81d6bf-fff5-4f5f-916c-c4b41d8f2f9f",
//                     "updated_at": "2022-09-12T10:18:12.913Z",
//                     "updated_by": "user profile id not available",
//                     "updatedate": 1663048909,
//                     "updatedby": "",
//                     "value": "50a9bfee-e80d-432b-969e-3b876c133004",
//                     "label": "tnj"
//                 },
//                 {
//                     "_id": "LevelData/10018",
//                     "_key": "10018",
//                     "_rev": "_exTue6K--A",
//                     "activestatus": true,
//                     "child_level": 3,
//                     "child_level_id": "7539ce8b-1e91-43a7-bbc9-89ea8270f27a",
//                     "child_name": "thirichy",
//                     "client_id": "client/10004",
//                     "country_id": "e68df9ff-a8c7-40e9-91c9-a834891d429d",
//                     "created_at": "2022-09-12T10:45:59.528Z",
//                     "created_by": "user profile id not available",
//                     "createdby": "",
//                     "createddate": 1662979558,
//                     "is_active": true,
//                     "level": 2,
//                     "level_id": "f619c5f8-d443-41a2-b190-bebaf151313e",
//                     "parent_child_level_id": "6a81d6bf-fff5-4f5f-916c-c4b41d8f2f9f",
//                     "updated_at": "2022-09-12T10:45:59.528Z",
//                     "updated_by": "user profile id not available",
//                     "updatedate": 1663048909,
//                     "updatedby": "",
//                     "value": "7539ce8b-1e91-43a7-bbc9-89ea8270f27a",
//                     "label": "thirichy"
//                 },
//                 {
//                     "_id": "LevelData/10024",
//                     "_key": "10024",
//                     "_rev": "_exTue6O--M",
//                     "activestatus": true,
//                     "child_level": 1,
//                     "child_level_id": "2fce84c2-efdd-4107-8f17-88ec87824cc9",
//                     "child_name": "newyork",
//                     "client_id": "client/10004",
//                     "country_id": "e68df9ff-a8c7-40e9-91c9-a834891d429d",
//                     "created_at": "2022-09-13T05:20:47.569Z",
//                     "created_by": "user profile id not available",
//                     "createdby": "",
//                     "createddate": 1663046446,
//                     "is_active": true,
//                     "level": 2,
//                     "level_id": "f619c5f8-d443-41a2-b190-bebaf151313e",
//                     "parent_child_level_id": "c91ace95-8d6d-4d5d-8363-c5b8f0119f25",
//                     "updated_at": "2022-09-13T05:20:47.569Z",
//                     "updated_by": "user profile id not available",
//                     "updatedate": 1663048909,
//                     "updatedby": "",
//                     "value": "2fce84c2-efdd-4107-8f17-88ec87824cc9",
//                     "label": "newyork"
//                 },
//                 {
//                     "_id": "LevelData/10025",
//                     "_key": "10025",
//                     "_rev": "_exTue6S---",
//                     "activestatus": true,
//                     "child_level": 2,
//                     "child_level_id": "b358de6e-6d56-40d9-9edd-00d132704d41",
//                     "child_name": "los vegas",
//                     "client_id": "client/10004",
//                     "country_id": "e68df9ff-a8c7-40e9-91c9-a834891d429d",
//                     "created_at": "2022-09-13T05:57:19.048Z",
//                     "created_by": "user profile id not available",
//                     "createdby": "",
//                     "createddate": 1663048638,
//                     "is_active": true,
//                     "level": 2,
//                     "level_id": "f619c5f8-d443-41a2-b190-bebaf151313e",
//                     "parent_child_level_id": "c91ace95-8d6d-4d5d-8363-c5b8f0119f25",
//                     "updated_at": "2022-09-13T05:57:19.048Z",
//                     "updated_by": "user profile id not available",
//                     "updatedate": 1663048909,
//                     "updatedby": "",
//                     "value": "b358de6e-6d56-40d9-9edd-00d132704d41",
//                     "label": "los vegas"
//                 }
//             ],
//             "client_id": "client/10004",
//             "country_id": "e68df9ff-a8c7-40e9-91c9-a834891d429d",
//             "createdby": "",
//             "createddate": 1662803674,
//             "is_active": true,
//             "level": 2,
//             "level_id": "f619c5f8-d443-41a2-b190-bebaf151313e",
//             "level_name": "City",
//             "updatedate": 1662803674,
//             "updatedby": "",
//             "value": "f619c5f8-d443-41a2-b190-bebaf151313e",
//             "label": "City"
//         },
//         "level3": {
//             "_id": "LevelStructure/10008",
//             "_key": "10008",
//             "_rev": "_exTlTc2---",
//             "activestatus": true,
//             "child": [
//                 {
//                     "_id": "LevelData/10014",
//                     "_key": "10014",
//                     "_rev": "_exTue6K---",
//                     "activestatus": true,
//                     "child_level_id": "50bc2b57-2b4f-405f-8bb3-bc75e6d77b7d",
//                     "child_level_no": 1,
//                     "child_name": "north",
//                     "client_id": "client/10004",
//                     "country_id": "e68df9ff-a8c7-40e9-91c9-a834891d429d",
//                     "created_at": "2022-09-12T10:35:03.866Z",
//                     "created_by": "user profile id not available",
//                     "createdby": "",
//                     "createddate": 1662978937,
//                     "is_active": true,
//                     "level": 3,
//                     "level_id": "67ed0d7a-0653-4d82-8581-39db99f9fd72",
//                     "parent_child_level_id": "6a81d6bf-fff5-4f5f-916c-c4b41d8f2f90",
//                     "updated_at": "2022-09-12T10:35:03.866Z",
//                     "updated_by": "user profile id not available",
//                     "updatedate": 1663048909,
//                     "updatedby": "",
//                     "value": "50bc2b57-2b4f-405f-8bb3-bc75e6d77b7d",
//                     "label": "north"
//                 },
//                 {
//                     "_id": "LevelData/10019",
//                     "_key": "10019",
//                     "_rev": "_exTue6K--C",
//                     "activestatus": true,
//                     "child_level": 2,
//                     "child_level_id": "bfb50be8-b782-4f6a-8b25-72240a86e372",
//                     "child_name": "south",
//                     "client_id": "client/10004",
//                     "country_id": "e68df9ff-a8c7-40e9-91c9-a834891d429d",
//                     "created_at": "2022-09-12T10:46:12.982Z",
//                     "created_by": "user profile id not available",
//                     "createdby": "",
//                     "createddate": 1662979572,
//                     "is_active": true,
//                     "level": 3,
//                     "level_id": "67ed0d7a-0653-4d82-8581-39db99f9fd72",
//                     "parent_child_level_id": "6a81d6bf-fff5-4f5f-916c-c4b41d8f2f90",
//                     "updated_at": "2022-09-12T10:46:12.982Z",
//                     "updated_by": "user profile id not available",
//                     "updatedate": 1663048909,
//                     "updatedby": "",
//                     "value": "bfb50be8-b782-4f6a-8b25-72240a86e372",
//                     "label": "south"
//                 }
//             ],
//             "client_id": "client/10004",
//             "country_id": "e68df9ff-a8c7-40e9-91c9-a834891d429d",
//             "createdby": "",
//             "createddate": 1662970309,
//             "is_active": true,
//             "level": 3,
//             "level_id": "67ed0d7a-0653-4d82-8581-39db99f9fd72",
//             "level_name": "cluster",
//             "updated_at": "2022-09-12T08:11:50.084Z",
//             "updated_by": "user profile id not available",
//             "updatedate": 1662970309,
//             "updatedby": "",
//             "value": "67ed0d7a-0653-4d82-8581-39db99f9fd72",
//             "label": "cluster"
//         },
//         "level4": {
//             "_id": "LevelStructure/10010",
//             "_key": "10010",
//             "_rev": "_exTlkNu---",
//             "activestatus": true,
//             "child": [
//                 {
//                     "_id": "LevelData/10020",
//                     "_key": "10020",
//                     "_rev": "_exTue6O---",
//                     "activestatus": true,
//                     "child_level": 1,
//                     "child_level_id": "241cec50-12f9-480b-b8b2-f2700454339e",
//                     "child_name": "gayathiri",
//                     "client_id": "client/10004",
//                     "country_id": "e68df9ff-a8c7-40e9-91c9-a834891d429d",
//                     "created_at": "2022-09-12T10:50:54.827Z",
//                     "created_by": "user profile id not available",
//                     "createdby": "",
//                     "createddate": 1662979854,
//                     "is_active": true,
//                     "level": 4,
//                     "level_id": "5d8066b6-c364-45d7-93ed-03e2d9636864",
//                     "parent_child_level_id": "50bc2b57-2b4f-405f-8bb3-bc75e6d77b7d",
//                     "updated_at": "2022-09-12T10:50:54.827Z",
//                     "updated_by": "user profile id not available",
//                     "updatedate": 1663048909,
//                     "updatedby": "",
//                     "value": "241cec50-12f9-480b-b8b2-f2700454339e",
//                     "label": "gayathiri"
//                 },
//                 {
//                     "_id": "LevelData/10021",
//                     "_key": "10021",
//                     "_rev": "_exTue6O--A",
//                     "activestatus": true,
//                     "child_level": 2,
//                     "child_level_id": "898b0933-a5c9-433f-94cb-8509bb00bf83",
//                     "child_name": "1",
//                     "client_id": "client/10004",
//                     "country_id": "e68df9ff-a8c7-40e9-91c9-a834891d429d",
//                     "created_at": "2022-09-12T10:51:18.312Z",
//                     "created_by": "user profile id not available",
//                     "createdby": "",
//                     "createddate": 1662979877,
//                     "is_active": true,
//                     "level": 4,
//                     "level_id": "5d8066b6-c364-45d7-93ed-03e2d9636864",
//                     "parent_child_level_id": "50bc2b57-2b4f-405f-8bb3-bc75e6d77b7d",
//                     "updated_at": "2022-09-12T10:51:18.312Z",
//                     "updated_by": "user profile id not available",
//                     "updatedate": 1663048909,
//                     "updatedby": "",
//                     "value": "898b0933-a5c9-433f-94cb-8509bb00bf83",
//                     "label": "1"
//                 },
//                 {
//                     "_id": "LevelData/10022",
//                     "_key": "10022",
//                     "_rev": "_exTue6O--C",
//                     "activestatus": true,
//                     "child_level": 3,
//                     "child_level_id": "35d3fc3c-ad8e-483e-b915-c6a45ca25030",
//                     "child_name": "2",
//                     "client_id": "client/10004",
//                     "country_id": "e68df9ff-a8c7-40e9-91c9-a834891d429d",
//                     "created_at": "2022-09-12T10:51:29.048Z",
//                     "created_by": "user profile id not available",
//                     "createdby": "",
//                     "createddate": 1662979888,
//                     "is_active": true,
//                     "level": 4,
//                     "level_id": "5d8066b6-c364-45d7-93ed-03e2d9636864",
//                     "parent_child_level_id": "50bc2b57-2b4f-405f-8bb3-bc75e6d77b7d",
//                     "updated_at": "2022-09-12T10:51:29.048Z",
//                     "updated_by": "user profile id not available",
//                     "updatedate": 1663048909,
//                     "updatedby": "",
//                     "value": "35d3fc3c-ad8e-483e-b915-c6a45ca25030",
//                     "label": "2"
//                 },
//                 {
//                     "_id": "LevelData/10023",
//                     "_key": "10023",
//                     "_rev": "_exTue6O--E",
//                     "activestatus": true,
//                     "child_level": 4,
//                     "child_level_id": "cf084597-eb56-44c3-b339-c0d160d6b8a7",
//                     "child_name": "3",
//                     "client_id": "client/10004",
//                     "country_id": "e68df9ff-a8c7-40e9-91c9-a834891d429d",
//                     "created_at": "2022-09-12T10:51:29.048Z",
//                     "created_by": "user profile id not available",
//                     "createdby": "",
//                     "createddate": 1662979888,
//                     "is_active": true,
//                     "level": 4,
//                     "level_id": "5d8066b6-c364-45d7-93ed-03e2d9636864",
//                     "parent_child_level_id": "50bc2b57-2b4f-405f-8bb3-bc75e6d77b7d",
//                     "updated_at": "2022-09-12T10:51:29.048Z",
//                     "updated_by": "user profile id not available",
//                     "updatedate": 1663048909,
//                     "updatedby": "",
//                     "value": "cf084597-eb56-44c3-b339-c0d160d6b8a7",
//                     "label": "3"
//                 }
//             ],
//             "client_id": "client/10004",
//             "country_id": "e68df9ff-a8c7-40e9-91c9-a834891d429d",
//             "createdby": "",
//             "createddate": 1662979588,
//             "is_active": true,
//             "level": 4,
//             "level_id": "5d8066b6-c364-45d7-93ed-03e2d9636864",
//             "level_name": "hospital",
//             "updated_at": "2022-09-12T10:46:29.451Z",
//             "updated_by": "user profile id not available",
//             "updatedate": 1662979588,
//             "updatedby": "",
//             "value": "5d8066b6-c364-45d7-93ed-03e2d9636864",
//             "label": "hospital"
//         }
//     }
// }

const ManageLevel = (props) => {
  const initialState = {
    client: props?.value?.client ?? [],
    // level1: props?.value?.level1 ?? null,
    // level2: props?.value?.level2 ?? null,
    // level3: props?.value?.level3 ?? null,
    // level4: props?.value?.level4 ?? null,
    // level5: props?.value?.level5 ?? null,
    // level6: props?.value?.level6 ?? null,
    // level7: props?.value?.level7 ?? null,
    // level8: props?.value?.level8 ?? null,
    // level9: props?.value?.level9 ?? null,
    // level10: props?.value?.level10 ?? null,
    // level11: props?.value?.level11 ?? null,
    // level12: props?.value?.level12 ?? null,
    level: props?.value?.level ?? [],
  };

  const [countryLevelData, setcountryLevelData] = React.useState({});

  const classes = useStyles(props);
  const dispatch = useDispatch();

  const [state, setState] = React.useState(initialState);
  const [countryData, setcountryData] = React.useState([]);

  React.useEffect(() => {
    getCountryvalue(props?.client_id);
    getCountyLeveldata(props?.client_id);
    // if (props.value) {
    // console.log("props?.mappedLocation", props?.mappedLocation);
    // }
    // eslint-disable-next-line
  }, [props.value]);

  const getCountryvalue = async () => {
    const data = await dispatch(
      actions.GET_SINGLE_CLIENT({ id: props?.client_id })
    );
    setcountryData(data?.payload);
    setState({ ...state, client: data?.payload?.data[0] });
  };

  const getCountyLeveldata = async () => {
    const data = await dispatch(
      actions.GET_MANAGE_LEVELS({ client_id: props?.client_id })
    );
    setcountryLevelData(data?.payload);
  };

  const changeState = (
    key,
    value,
    startLevel = 0,
    parentLevel,
    index,
    from
  ) => {
    let updatedState = state;
    if (value === null) {
      updatedState.level.splice(index, 1);
      let newState = {
        ...state,
        ...updatedState,
      };
      props?.handleChange(newState);
    } else {
      if (from === "level") {
        updatedState["level"][index] = {
          [key]: { ...value, parentLevel: { ...parentLevel, level: key } },
        };
        onStateChangeCallback(key, value, updatedState, startLevel, index);
      } else {
        updatedState[key] = {
          ...value,
          parentLevel: { ...parentLevel, level: key },
        };
        onStateChangeCallback(key, value, updatedState, startLevel);
      }
    }
  };
  const onStateChangeCallback = (
    key,
    value,
    updatedState,
    startLevel = 0,
    index
  ) => {
    if (key === "country") {
      updatedState = clearLevel(startLevel, updatedState);
    } else if (state?.level?.length > 0) {
      updatedState = clearLevel(index, updatedState, key);
    }

    let newState = {
      ...state,
      ...updatedState,
    };

    setState(newState);

    props?.handleChange(newState);
  };

  const clearLevel = (startLevel, updatedState, key) => {
    // debugger;
    state?.level?.map((i, index) => {
      //   debugger;
      if (index > startLevel) {
        updatedState.level[index] = null;
      }
      return i;
    });
    return updatedState;
  };

  const giveMeLevelChilds = (level, child, index) => {
    if (level === 1) {
      return child;
    } else {
      const levelKey = `level${level - 1}`;
      const previousLevel =
        state?.level[index]?.[levelKey]?.child_level_id ?? "";
      let newChild = child.filter(
        (ch) => previousLevel === ch.parent_child_level_id
      );
      return newChild;
    }
  };

  return (
    <div className={classes.root}>
      <div>
        <Grid
          container
          direction={props?.showAs ? props?.showAs : "row"}
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ fontSize: 16 }}
              className={classes.hash}
            >
              {props?.label ? props?.label : "Location Level"}{" "}
              {props.isrequired && (
                <Typography variant="caption" style={{ color: "red" }}>
                  *
                </Typography>
              )}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={12} className={classes.selectField}>
            <Typography variant="body2" className={classes.levelName}>
              Client
            </Typography>

            <TextField
              type="text"
              fullWidth
              value={state?.client?.client_name}
              isDisabled={(props?.disabled || props?.isReadonly) ?? false}
            ></TextField>

            {props?.errorValidation?.error && (
              <div>
                <Typography variant="caption" className={classes.errorText}>
                  {props?.errorValidation?.errorMessage}
                </Typography>
              </div>
            )}
            {countryLevelData?.loading && !countryLevelData?.error && (
              <Typography variant="caption" color="textSecondary">
                {"Fetching Country Levels..."}
              </Typography>
            )}
            {!countryLevelData?.loading && countryLevelData?.error && (
              <Typography variant="caption" color="textSecondary">
                {"Something Went wrong, Unable to fetch country levels"}
              </Typography>
            )}
          </Grid>

          {!countryLevelData?.loading &&
            !countryLevelData?.error &&
            Object.values(countryLevelData?.data ?? {}).map((level, key) => {
              let currentLevel = key + 1;
              let stateKey = `level${currentLevel}`;

              // to avoid too many rerenders nested condition is used
              if (!props?.isAdmin) {
                if (state?.[stateKey] === null) {
                  if (props?.mappedLocation?.[stateKey] !== null) {
                    changeState(
                      stateKey,
                      props?.mappedLocation?.[stateKey],
                      currentLevel
                    );
                  }
                }
              }
              if (level.length === 0) {
                return <div key={key + 10}></div>;
              }
              return (
                <Grid key={level?.level_id} item xs={12} sm={12} md={12}>
                  <Typography variant="body2" className={classes.levelName}>
                    {level?.level_name}
                  </Typography>
                  <Select
                    id={props?.id ? props?.id + "select" + key : "select"}
                    className="basic-single"
                    classNamePrefix="select"
                    menuPosition={"fixed"}
                    isLoading={countryLevelData?.loading}
                    isClearable
                    isSearchable
                    isDisabled={
                      !props?.isAdmin
                        ? props?.mappedLocation?.[stateKey] !==
                          (null || undefined)
                          ? true
                          : false
                        : (props?.disabled || props?.isReadonly) ?? false
                    }
                    value={state?.level[key]?.[stateKey] ?? {}}
                    onChange={(value) =>
                      changeState(
                        stateKey,
                        value,
                        currentLevel,
                        level,
                        key,
                        "level"
                      )
                    }
                    placeholder={"Select " + level?.level_name}
                    options={[
                      //   { value: "all", label: "All" },
                      ...giveMeLevelChilds(currentLevel, level?.child, key - 1),
                    ]}
                  />
                </Grid>
              );
            })}
        </Grid>
      </div>
    </div>
  );
};

export default ManageLevel;
