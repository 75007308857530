import React from 'react'
import { SvgIcon, } from '@material-ui/core'


export function Person(props) {

    return (
        <SvgIcon viewBox="0 0 9.62 14.921" style={{ fontSize: '1rem', marginRight: "8px" }}>
            <path d="M14,4a3.75,3.75,0,1,0,3.75,3.75A3.758,3.758,0,0,0,14,4Zm0,1.125A2.625,2.625,0,1,1,11.375,7.75,2.616,2.616,0,0,1,14,5.125ZM9.492,13A1.5,1.5,0,0,0,8,14.491v.571a3.494,3.494,0,0,0,1.99,3.023A8.44,8.44,0,0,0,14,19a8.44,8.44,0,0,0,4.01-.914,3.624,3.624,0,0,0,1.9-2.461H20V14.491A1.5,1.5,0,0,0,18.507,13Zm0,1.125h9.016a.359.359,0,0,1,.368.367V14.5h0v.562A2.285,2.285,0,0,1,17.466,17.1,7.475,7.475,0,0,1,14,17.874a7.475,7.475,0,0,1-3.466-.773,2.285,2.285,0,0,1-1.408-2.039v-.571A.359.359,0,0,1,9.492,14.124Z" transform="translate(-8 -4)" fill="#4448C2" />
        </SvgIcon>
    )
}