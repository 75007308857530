import React from "react";
import {
  Button,
  Grid,
  Typography,
  makeStyles,
  Box,
  IconButton,
  Avatar,
  Tooltip,
} from "@material-ui/core";
import styled from "styled-components";
// import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { device } from "../../utils";
// import { Badge } from "reactour";
import CloseIcon from "@material-ui/icons/Close";
// import { Tooltip } from "@mui/material";
// import {steps1} from '../../Steps'
export const CardList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* height: 52px; */
  /* padding-left: ${(props) => (props.nopadddingL ? "0" : "16px")};
  padding-right: 16px; */
  background-color: #ffffff;
  color: ${(props) => props.clr};
  padding: 8px;

  border-bottom: 1px solid #e0e0e0;
  /* border-radius: 8px; */
  margin: 0px 8px;
  /* @media ${device?.mobileL} {
    margin: 8px;
  }
  @media ${device?.mobileS} {
    margin: 4px;
  }
  @media ${device?.mobileM} {
    margin: 4px;
  }
  @media ${device?.mobileXS} {
    margin: 4px;
  } */
  /* background: #fff; */
`;
export const Circle = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${(props) => (props.isActive ? "#3bb213" : "#e0e0e0")};
  margin: 8px;
`;
export const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

export const NumberTag = styled.div`
  min-width: 20px;
  width: 6px;
  height: ${(props) => (props.isempty ? "25px" : "")};
  background-color: ${(props) => props.clr};
  color: ${(props) => props.bgcolor};
  padding: 10px 7px;
  border-radius: 0 8px 8px 0;
  box-sizing: border-box;
  margin-right: ${(props) => props.marginRight};
  @media ${device?.tablet} {
    margin: 8px;
    font-size: 1rem;
  }
  @media ${device?.mobileL} {
    margin: 8px;
    font-size: 1rem;
  }
  @media ${device?.mobileS} {
    margin: 4px;
    font-size: 0.6rem;
  }
  @media ${device?.mobileM} {
    margin: 4px;
    font-size: 0.6rem;
  }
  @media ${device?.mobileXS} {
    margin: 4px;
    padding: 5px 7px;
    font-size: 0.6rem;
  }
`;

export const FlexRowMargin = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin: 16px;
  @media ${device?.tablet} {
    margin: 8px;
    font-size: 1rem;
  }
  @media ${device?.mobileL} {
    margin: 8px;
    font-size: 0.8rem;
  }
  @media ${device?.mobileS} {
    margin: 4px;
    font-size: 0.6rem;
  }
  @media ${device?.mobileM} {
    margin: 4px;
    font-size: 0.6rem;
  }
  @media ${device?.mobileXS} {
    margin: 4px;
    font-size: 0.6rem;
  }
`;

export const FlexRowMargin2 = styled.div`
  display: flex;
  align-items: center;
  margin: 0 4px;
`;
export const ActiveText = styled.p`
  color: ${(props) => (props.isActive ? "#3bb213" : "#e0e0e0")};
  font-family: TDS_regular;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.57;
`;

const useStyles = makeStyles((theme) => ({
  textResponsive: {
    fontSize: "0.70rem !important",
    maxWidth: "250px",
    marginRight: "16px !important",

    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem !important",
      marginRight: "16px !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem !important",
      marginRight: "16px !important",
    },
  },
  IconButton: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));
const stringToColor = (string) => {
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
};
export const NotifyCard = (props) => {
  const {
    title = "",
    index = "",
    data,
    markasread = () => false,
    hideicon = false,
    // type = "",
    // numtag = false,
  } = props;
  const classes = useStyles();
  return (
    // <TourProvider steps={steps1}>
    <Grid item lg={12} md={12} xs={12} sm={12} key={index}>
      <CardList nopadddingL={true}>
        {/* {numtag && (
          // <NumberTag
          //   clr={theme.palette.primary.main}
          //   bgcolor={theme.palette.background.common}
          //   // isempty={isempty ? true : false}
          //   marginRight={"16px"}
          //   style={{ flex: "0 0 auto" }}
          // >
          //   <Typography variant="body2" className={classes.textResponsive}>{`${
          //     index + 1
          //   }`}</Typography>
          // </NumberTag>
          <Typography variant="body2" className={classes.textResponsive}>{`${
            index + 1
          }`}</Typography>
        )} */}
        {/* <Avatar style={{ background: stringToColor(type) }}>{`${
          type.split(" ")[0][0]
        }`}</Avatar> */}

        <Box style={{ flex: "1 1 auto", paddingLeft: "8px" }}>
          {/* <FlexRowMargin> */}
          <Typography
            variant="caption"
            color={data?.is_active === true ? "textPrimary" : "textSecondary"}
          >
            {/* style={data?.Notification_count===0?{textDecoration:"unset"}:{textDecoration:"line-through"}}  */}
            {title}
          </Typography>

          {/* </FlexRowMargin> */}
        </Box>
        {!hideicon && (
          <Box style={{ flex: "0 0 auto" }}>
            {/* <Button
              onClick={() => markasread(data)}
              size="small"
              variant="outlined"
              color="primary"
              disabled={data?.Notification_count === 0 ? false : true}
            >
              Mark as read
            </Button> */}
            <Tooltip title="Mark as read" arrow>
              <IconButton
                onClick={() => markasread(data)}
                // variant="outlined"
                // color="primary"
                className={classes.IconButton}
                // color={data?.Notification_count === 0 ? "" : "primary"}
                disabled={data?.is_active === true ? false : true}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </CardList>
    </Grid>
  );
};
