import React from "react";
import jwt_decode from "jwt-decode";
import {
  Grid,
  makeStyles,
  Typography,
  Divider,
  IconButton,
  Button,
  LinearProgress,
} from "@material-ui/core";
import {
  InternatlServerError,
  NotFoundSection,
  FilterBuilder,
} from "../../components";
import LockIcon from "@material-ui/icons/Lock";
import { Drag, DragDropIcon } from "../../assets";
import { SearchFilter, CardListLoader } from "../../componentsV2";
import { DrawerContext } from "../../contexts";
import { Message, DrawerProps } from "../../utils";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
// import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import FormRenderer from "../formRenderer";
import Tooltip from "@material-ui/core/Tooltip";
import { useEffect } from "react";
import { AuthContext } from "../../contexts";
const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    height: "100%",

    padding: theme.spacing(2),
  },
  paper: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #0000001A",
    borderRadius: 10,
    overflowX: "hidden",
    height: "100%",
    // height:'calc(100vh - 155px)'
    // padding: 20,
  },
  SearchFilter: {
    padding: theme.spacing(2),
  },
  cardDetails: {
    padding: theme.spacing(2),
    cursor: "pointer",
  },
  dragComponent: {
    flexGrow: 1,
    padding: theme.spacing(2),
    height: "100%",
  },
  borderStyle: {
    border: "2px dashed #110F474D",
    background: "#110F4708 0% 0% no-repeat padding-box",
    borderRadius: 7,
    height: "100%",
    padding: theme.spacing(2),
  },
  wrapper: {
    width: 100,
    height: 100,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #110F471A",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    marginBottom: 10,
  },
  flexStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  droppedContent: {
    // background: theme.palette.primary.light,
    boxShadow: "0px 15px 25px #110F470F",
    border: "1px solid #110F471A",
    borderRadius: 7,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  droppedWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  viewPortHeight: {
    height: "calc(100vh - 285px)",
    overflowY: "auto",
  },
  info: {
    // color: theme.palette.secondary.main,
  },
  info2: {
    // color: theme.palette.primary.main,
  },
  remove: {
    // color: theme.palette.primary.main,
  },
  formName: {
    // color: theme.palette.primary.main,
  },
  diseaseName: {
    // color: theme.palette.hash.primary,
  },
}));

export const Notify = (props) => {
  const classes = useStyles(props);
  const vaule = React.useContext(AuthContext);
  const drawerContext = React.useContext(DrawerContext);
  const [state, setState] = React.useState([]);
  const [formRendererOpen, setFormRendererOpen] = React.useState(false);

  let { data = [], loading, error } = props;
  // ! Filter Only Data That Is permsnread Is TRUE

  data = data.filter(
    (v) =>
      jwt_decode(
        `eyJhbGciOiJIUzI1NiJ9.${localStorage.getItem(
          "permissionData"
        )}.FXibJVNHsvJ6Ff-N9XtTTom9cGExRqsldHbrhAOqRUg`
      ).filter(
        (e) =>
          e?.permissionRepo?.unique_id?.startsWith("forms") &&
          e?.permissionRepo?.unique_id === `forms/${v.form_id}` &&
          e?.permsnread === true
      ).length !== 0
  );

  // React.useLayoutEffect(() => {
  //   setFormRendererOpen(false);
  // }, [props.renderPanel]);
  // useEffect(() => {
  //   if (data?.length == 1) {
  //     setState(data);
  //     setFormRendererOpen(true);
  //     console.log("data", data);
  //     console.log("state", state);
  //     console.log("formRendererOpen", formRendererOpen);
  //   }
  // }, [data]);
  //Function Ends Here
  const onSearchChange = (value) => {
    if (value && value?.length % 2 === 0) {
      props.getNotificationForms(value);
    } else if (!value) {
      props.getNotificationForms();
    }
  };
  const onFilterClicked = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.left,
      variant: DrawerProps.variant.temporary,
      component: (
        <FilterBuilder
          id="notify"
          title="Disease Filter"
          showClassification={true}
          classification={props?.classification}
          showIcd10={true}
          icd10={props?.icd10}
          applyFilter={applyFilter}
        />
      ),
      onClose: () => onDrawerClosedLeft(),
    });
  };
  const handleGoBack = () => {
    setFormRendererOpen(false);
  };
  const applyFilter = (data, isFilterApplied) => {
    onDrawerClosedLeft();
    props?.applyFilter(data, isFilterApplied);
  };

  const onDrawerClosedLeft = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: false,
      direction: DrawerProps.direction.left,
    });
  };

  const copy = (result) => {
    const draggedData =
      data && data?.filter((i) => i.form_id === result.draggableId);
    setState(draggedData);
  };

  const removeDroppedContent = () => {
    setState([]);
  };

  const gotoNotification = () => {
    setFormRendererOpen(true);
    // history.push(Routes.add_form_notify_parent + state[0].form_id);
  };

  const onDragEnd = (result) => {
    const { destination } = result;

    if (!destination) {
      return;
    }

    copy(result);
  };

  const getValue = (form) => {
    setState([form]);
  };

  data = data.reduce((acc, curr) => {
    acc.push({
      ...JSON.parse(JSON.stringify(curr)),
      period: curr.mapped_diseases.filter(
        (r) => r.formId === `forms/${curr.form_id}`
      )[0].period,
    });
    return acc;
  }, []);
  console.log(data);
  const disabledOrNot = (perioddata) => {
    console.log(perioddata);
    return !perioddata.some(
      (e) =>
        (new Date(e.from).getMonth() + 1 >= new Date().getMonth() + 1 &&
          new Date(e.from).getDate() >= new Date().getDate()) ||
        (new Date(e.to).getMonth() + 1 >= new Date().getMonth() + 1 &&
          new Date(e.to).getDate() >= new Date().getDate())
    );
  };

  return (
    <>
      {/* {data?.length !== 1 ? (
        <>
          {!formRendererOpen && (
            <DragDropContext onDragEnd={onDragEnd}>
              <div className={classes.root}>
                <Grid container spacing={1} style={{ height: "inherit" }}>
                  <Grid item xs={12} sm={12} md={3}>
                    <div className={classes.paper}>
                      <div className={classes.formList}>
                        <div className={classes.SearchFilter}>
                          <SearchFilter
                            id="notify"
                            title="Assessment"
                            placeholder="Search..."
                            // loading={data?.length > 0 ? loading : false}
                            onSearchChange={onSearchChange}
                            hideFilter={true}
                            onFilterClicked={onFilterClicked}
                            isFilterApplied={props?.isFilterApplied}
                          />
                        </div>
                        <Divider />
                        {loading && <LinearProgress />}

                        {loading && !error && data?.length === 0 && (
                          <CardListLoader count={10} />
                        )}
                        {!loading && error && data?.length === 0 && (
                          <InternatlServerError
                            message={Message.notification_error}
                            onRetry={props.getNotificationForms}
                          />
                        )}
                        {!loading && !error && data?.length === 0 && (
                          <NotFoundSection
                            message={Message.notification_not_found}
                          />
                        )}

                        <Droppable droppableId="ITEMS" isDropDisabled={true}>
                          {(provided, snapshot) => (
                            <div
                              id="droppable"
                              className={classes.viewPortHeight}
                              ref={provided.innerRef}
                              isDraggingOver={snapshot.isDraggingOver}
                            >
                              {data?.length > 0 &&
                                data.map((form, index) => (
                                  <Draggable
                                    id={`${"draggable" + index}`}
                                    key={form?.form_id}
                                    draggableId={form.form_id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <React.Fragment>
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          isDragging={snapshot.isDragging}
                                        >
                                          <div
                                            onDoubleClick={() => getValue(form)}
                                            id={"card" + index}
                                          >
                                            <div
                                              className={classes.cardDetails}
                                            >
                                              <Grid
                                                container
                                                direction="row"
                                                alignItems="center"
                                                justify="space-between"
                                              >
                                                <Grid item xs={9}>
                                                  <Typography
                                                    variant="body1"
                                                    style={{ color: "#06094D" }}
                                                  >
                                                    {form?.form_name}
                                                  </Typography>
                                                  {form?.mapped_diseases
                                                    ?.length > 0 && (
                                                    <Typography
                                                      variant="caption"
                                                      style={{
                                                        color: "#83859B",
                                                      }}
                                                    >
                                                      {form?.mapped_diseases
                                                        ?.map(
                                                          (_) => _?.disease_name
                                                        )
                                                        ?.toString()}
                                                    </Typography>
                                                  )}
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={3}
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end",
                                                  }}
                                                >
                                                  <Drag />
                                                </Grid>
                                              </Grid>
                                            </div>

                                            <Divider />
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    )}
                                  </Draggable>
                                ))}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={9}>
                    <div className={classes.paper}>
                      <div className={classes.dragComponent}>
                        <Droppable droppableId="ITEMS2">
                          {(provided, snapshot) => (
                            <div
                              id="droppable2item"
                              className={`${classes.borderStyle} ${
                                state?.length <= 0 && classes.flexStyle
                              }`}
                              ref={provided.innerRef}
                              isDraggingOver={snapshot.isDraggingOver}
                            >
                              {state.length ? (
                                state?.map((form, index) => (
                                  <div className={classes.droppedWrapper}>
                                    <div
                                      key={index}
                                      id={`${"drop" + index}`}
                                      className={classes.droppedContent}
                                    >
                                      <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justify="space-between"
                                      >
                                        <Grid
                                          item
                                          xs={10}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Typography
                                            variant="body1"
                                            className={classes.formName}
                                          >
                                            {form?.form_name}
                                            {form?.mapped_diseases?.length >
                                              0 && (
                                              <Typography
                                                variant="caption"
                                                className={classes.diseaseName}
                                              >
                                                {" (" +
                                                  form?.mapped_diseases
                                                    ?.map(
                                                      (_) =>
                                                        _?.disease_name ?? ""
                                                    )
                                                    ?.toString() +
                                                  ")"}
                                              </Typography>
                                            )}
                                          </Typography>
                                        </Grid>
                                        <Grid item style={{ display: "flex" }}>
                                          <IconButton
                                            id={"removeButton" + index}
                                            onClick={removeDroppedContent}
                                            className={classes.remove}
                                          >
                                            <HighlightOffIcon />
                                          </IconButton>
                                        </Grid>
                                      </Grid>
                                    </div>
                                    <Grid
                                      container
                                      justify="flex-end"
                                      alignItems="flex-end"
                                      style={{ flex: 1 }}
                                    >
                                      <Grid item>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          onClick={gotoNotification}
                                          id="goToNotification"
                                        >
                                          Start
                                          <div
                                            style={{
                                              marginLeft: 5,
                                              display: "flex",
                                            }}
                                          >
                                            <ArrowRightAltIcon />
                                          </div>
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </div>
                                ))
                              ) : (
                                <div
                                  className={classes.flexStyle}
                                  style={{ flexDirection: "column" }}
                                >
                                  <div className={classes.wrapper}>
                                    <DragDropIcon />
                                  </div>
                                  <Typography
                                    variant="h1"
                                    style={{
                                      fontSize: 20,
                                      textAlign: "center",
                                    }}
                                  >
                                    Please drag and drop a disease to notify.
                                  </Typography>
                                </div>
                              )}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </DragDropContext>
          )}
          {formRendererOpen && (
            <FormRenderer
              secName={0}
              title="notifyAdd"
              secToShow={[0, null]}
              state={state}
              goBack={handleGoBack}
              userData={props.userData}
              hideBtn={false}
              hidePatient={true}
            />
          )}
        </>
      ) : (
        <FormRenderer
          secName={0}
          title="notifyAdd"
          secToShow={[0, null]}
          state={data}
          goBack={handleGoBack}
          userData={props.userData}
          hideBtn={true}
          hidePatient={true}
        />
      )} */}
      <>
        {!formRendererOpen && (
          <DragDropContext onDragEnd={onDragEnd}>
            <div className={classes.root}>
              <Grid container spacing={1} style={{ height: "inherit" }}>
                <Grid item xs={12} sm={12} md={3}>
                  <div className={classes.paper}>
                    <div className={classes.formList}>
                      <div className={classes.SearchFilter}>
                        <SearchFilter
                          id="notify"
                          title="Assessment"
                          placeholder="Search..."
                          // loading={data?.length > 0 ? loading : false}
                          onSearchChange={onSearchChange}
                          hideFilter={true}
                          onFilterClicked={onFilterClicked}
                          isFilterApplied={props?.isFilterApplied}
                        />
                      </div>
                      <Divider />
                      {loading && <LinearProgress />}

                      {loading && !error && data?.length === 0 && (
                        <CardListLoader count={10} />
                      )}
                      {!loading && error && data?.length === 0 && (
                        <InternatlServerError
                          message={Message.notification_error}
                          onRetry={props.getNotificationForms}
                        />
                      )}
                      {!loading && !error && data?.length === 0 && (
                        <NotFoundSection
                          message={Message.notification_not_found}
                        />
                      )}

                      <Droppable droppableId="ITEMS" isDropDisabled={true}>
                        {(provided, snapshot) => (
                          <div
                            id="droppable"
                            className={classes.viewPortHeight}
                            ref={provided.innerRef}
                            isDraggingOver={snapshot.isDraggingOver}
                          >
                            {data?.length > 0 &&
                              data.map((form, index) => (
                                <Draggable
                                  isDragDisabled={disabledOrNot(form.period)}
                                  id={`${"draggable" + index}`}
                                  key={form?.form_id}
                                  draggableId={form.form_id}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <React.Fragment>
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        isDragging={snapshot.isDragging}
                                      >
                                        <div
                                          // onDoubleClick={() => getValue(form)}
                                          id={"card" + index}
                                        >
                                          <div className={classes.cardDetails}>
                                            <Grid
                                              container
                                              direction="row"
                                              alignItems="center"
                                              justify="space-between"
                                            >
                                              <Grid item xs={9}>
                                                <Typography
                                                  variant="body1"
                                                  style={{ color: "#06094D" }}
                                                >
                                                  {form?.form_name}
                                                </Typography>
                                                {/* {form?.mapped_diseases?.length >
                                                  0 && (
                                                  <Typography
                                                    variant="caption"
                                                    style={{
                                                      color: "#83859B",
                                                    }}
                                                  >
                                                    {form?.mapped_diseases
                                                      ?.map(
                                                        (_) => _?.disease_name
                                                      )
                                                      ?.toString()}
                                                  </Typography>
                                                )} */}
                                              </Grid>
                                              <Grid
                                                item
                                                xs={3}
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "flex-end",
                                                  placeItems: "center",
                                                  gap: "1em",
                                                  margin: "auto",
                                                }}
                                              >
                                                {disabledOrNot(form.period) && (
                                                  <LockIcon />
                                                )}
                                                <Drag />
                                              </Grid>
                                            </Grid>
                                          </div>

                                          <Divider />
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  )}
                                </Draggable>
                              ))}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                  <div className={classes.paper}>
                    <div className={classes.dragComponent}>
                      <Droppable droppableId="ITEMS2">
                        {(provided, snapshot) => (
                          <div
                            id="droppable2item"
                            className={`${classes.borderStyle} ${
                              state?.length <= 0 && classes.flexStyle
                            }`}
                            ref={provided.innerRef}
                            isDraggingOver={snapshot.isDraggingOver}
                          >
                            {state.length ? (
                              state?.map((form, index) => (
                                <div className={classes.droppedWrapper}>
                                  <div
                                    key={index}
                                    id={`${"drop" + index}`}
                                    className={classes.droppedContent}
                                  >
                                    <Grid
                                      container
                                      direction="row"
                                      alignItems="center"
                                      justify="space-between"
                                    >
                                      <Grid
                                        item
                                        xs={10}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography
                                          variant="body1"
                                          className={classes.formName}
                                        >
                                          {form?.form_name}
                                          {/* {form?.mapped_diseases?.length >
                                            0 && (
                                            <Typography
                                              variant="caption"
                                              className={classes.diseaseName}
                                            >
                                              {" (" +
                                                form?.mapped_diseases
                                                  ?.map(
                                                    (_) => _?.disease_name ?? ""
                                                  )
                                                  ?.toString() +
                                                ")"}
                                            </Typography>
                                          )} */}
                                        </Typography>
                                      </Grid>
                                      <Grid item style={{ display: "flex" }}>
                                        <IconButton
                                          id={"removeButton" + index}
                                          onClick={removeDroppedContent}
                                          className={classes.remove}
                                        >
                                          <HighlightOffIcon />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <Grid
                                    container
                                    justify="flex-end"
                                    alignItems="flex-end"
                                    style={{ flex: 1 }}
                                  >
                                    <Grid item>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={gotoNotification}
                                        id="goToNotification"
                                      >
                                        Start
                                        <div
                                          style={{
                                            marginLeft: 5,
                                            display: "flex",
                                          }}
                                        >
                                          <ArrowRightAltIcon />
                                        </div>
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </div>
                              ))
                            ) : (
                              <div
                                className={classes.flexStyle}
                                style={{ flexDirection: "column" }}
                              >
                                <div className={classes.wrapper}>
                                  <DragDropIcon />
                                </div>
                                <Typography
                                  variant="h1"
                                  style={{
                                    fontSize: 20,
                                    textAlign: "center",
                                  }}
                                >
                                  Please drag and drop a disease to notify.
                                </Typography>
                              </div>
                            )}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </DragDropContext>
        )}
        {formRendererOpen && (
          <FormRenderer
            secName={0}
            title="notifyAdd"
            secToShow={[0, null]}
            state={state}
            goBack={handleGoBack}
            userData={props.userData}
            hideBtn={false}
            hidePatient={true}
          />
        )}
      </>
    </>
  );
};
